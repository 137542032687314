import {
  OnChange,
  OnChangeWithValidation,
  OnCheckBoxChange,
  OnCurrencyChange,
  OnCurrencyChangeWithValidation,
  OnDateBlur,
  OnDateChange,
  OnDateChangeWithValidation,
} from 'helpers/ValidationHelper';
import React from 'react';

export class EditBaseClass<P = {}, S = {}> extends React.Component<P, S> {
  protected onChangeHandler: any;
  protected onChangeWithValidationHandler: any;
  protected onCurrencyChangeHandler: any;
  protected onCurrencyChangeWithValidationHandler: any;
  protected onDateChangeHandler: any;
  protected onDateChangeWithValidationHandler: any;
  protected onDateBlurHandler: any;
  protected onCheckBoxChangeHandler: any;
  protected validationData: any = {};
  protected genericChangeHandler: any;

  constructor(props: P) {
    super(props);
    this.onChangeHandler = (event: any) => OnChange(this, this.state, event);
    this.onChangeWithValidationHandler = (event: any, allowLiteralNull: boolean = false) =>
      OnChangeWithValidation(this, this.state, this.validationData, event, allowLiteralNull);
    this.onCurrencyChangeHandler = (event: any) => OnCurrencyChange(this, this.state, event);
    this.onCurrencyChangeWithValidationHandler = (event: any) =>
      OnCurrencyChangeWithValidation(this, this.state, this.validationData, event);
    this.onDateChangeHandler = (value: any, name: any) => OnDateChange(this, this.state, value, name);
    this.onDateChangeWithValidationHandler = (value: any, name: any) =>
      OnDateChangeWithValidation(this, this.state, this.validationData, value, name);
    this.onDateBlurHandler = (value: any, name: any) => OnDateBlur(this, this.state, this.validationData, value, name);
    this.onCheckBoxChangeHandler = (event: any) => OnCheckBoxChange(this, this.state, event);

    this.genericChangeHandler = (event: any, name?: string, data?: any, allowLiteralNull: boolean = false) => {
      let nameString;
      let value;
      if (data) {
        if (name && name.includes(';')) {
          nameString = name.split(';')[0];
          const ev = {
            target: { name: name.split(';')[1], value: data.selectedText },
          };
          this.onChangeHandler(ev);
        } else {
          nameString = name;
        }
        value = event;
      } else if (name !== undefined && typeof event === 'object' && 'target' in event && 'checked' in event.target) {
        nameString = event.target.name;
        value = event.target.checked;
      } else if (name) {
        nameString = name;
        value = event;
      } else if ('target' in event) {
        nameString = event.target.name;
        nameString = nameString.includes(';') ? nameString.split(';')[0] : nameString;
        value = event.target.value;
        if (
          'nativeEvent' in event &&
          'target' in event.nativeEvent &&
          'selectedIndex' in event.nativeEvent.target &&
          event.target.name.includes(';')
        ) {
          const index = event.nativeEvent.target.selectedIndex;
          const selectedText = event.nativeEvent.target[index].text;
          const selectedName = event.target.name.split(';')[1];
          const ev = { target: { name: selectedName, value: selectedText } };
          this.onChangeHandler(ev);
        }
      } else if ('value' in event) {
        nameString = event.name;
        value = event.value;
      }
      let e = { target: { name: nameString, value } };
      if (nameString in this.validationData) {
        this.onChangeWithValidationHandler(e, allowLiteralNull);
      } else {
        this.onChangeHandler(e);
      }
    };
  }
}
