import { navigate, RouteComponentProps } from '@reach/router';
import { SET_SEARCH_CRITERIA } from 'actions/SearchCriteria/searchCriteriaActionTypes';
import { DevXCalendarObject } from 'components/DevXCalendarControl/DevXCalendarObject';
import DropdownControl from 'components/DropDown/DropdownControl';
import DropDownControlCodeTableRow from 'components/DropDown/DropDownControlCodeTable/DropDownControlCodeTableRow';
import DropdownControlSqlRow from 'components/DropDown/DropDownControlSql/DropDownControlSqlRow';
import { MethodType } from 'components/DropDown/MethodType';
import GridControl, { calcGridInspectorWidth } from 'components/GridControl';
import { InspectorSearch } from 'components/InspectorSearch/InspectorSearch';
import PageControl from 'components/PageControl/PageControl';
import TextboxControlRow from 'components/TextboxControl/TextboxControlRow';
import { fetchData, IDataObject } from 'data/DataConnector';
import { setEntityId } from 'helpers/AuthenticationHelper';
import {
  OnChange,
  OnChangeWithValidation,
  OnDateBlur,
  OnDateChange,
  OnDateChangeWithValidation,
  OnSaveValidation,
  ValidationModel,
  ValidationType,
} from 'helpers/ValidationHelper';
import * as React from 'react';
import { connect } from 'react-redux';
import { ControllerNameEnum } from 'util/enums/Enum';
import { InlineRowComponent, RangeStyledComponentRow } from 'util/helpers/CommonComponents';
import { getTrimmedValue, getUsername } from 'util/helpers/helperFunctions';
import 'views/DealList/DealList.css';
import { EditBaseClass } from 'views/EditBaseClass';
import SettingValueEdit from 'views/Includes/SettingValueEdit';
import SelectDealVCM from './SelectDealVCM';
import SelectDealVDA from './SelectDealVDA';

const DEAL_LIST_HOME_PAGE: string = 'DEAL_LIST_HOME_PAGE';

/*
We can use IProps Interface, this is custom page specific handling.
*/
type Props = {
  name?: string;
  searchCriteriaDict: any;
  changeSearchCriteria: any;
  isBackClicked: boolean;
} & RouteComponentProps<{}>;

/*
State Interface
*/
interface IState {
  isLoading: boolean;
  showSearch: boolean;
  dealListData: IDataObject;
  searchCriteria: {
    cId: string;
    closingDateBegin?: Date;
    closingDateEnd?: Date;
    dealName: string;
    dealType: string;
    entityStatus: string;
    entityStatusDescription: string;
    luId: string;
    reinvestmentPeriodDate?: Date;
    reinvestmentPeriodDateCompare: string;
    tId: string;
    userId: string;
  };
  editParameters: any;
  showDataAnalystEdit: boolean;
  showDirectorEdit: boolean;
  showSettingValueEdit: boolean;
  configCode: string;
  entityId: number;
}

class DealList extends EditBaseClass<Props, IState> {
  private searchCriteria = {
    'Entity Status': 'Active',
  };
  private linkedColumns: any;
  private reinvestmentPeriodDateData: any;

  constructor(props: Props) {
    super(props);

    // Override the events because we have sub level for search Criteria, If we don't have searchCriteria
    // and directly have the fields in state. Then No need to override.
    this.onChangeHandler = (event: any) => OnChange(this, this.state.searchCriteria, event);
    this.onChangeWithValidationHandler = (event: any) =>
      OnChangeWithValidation(this, this.state.searchCriteria, this.validationData, event);
    this.onDateChangeHandler = (value: any, name: any) => OnDateChange(this, this.state.searchCriteria, value, name);
    this.onDateChangeWithValidationHandler = (value: any, name: any) =>
      OnDateChangeWithValidation(this, this.state.searchCriteria, this.validationData, value, name);
    this.onDateBlurHandler = (value: any, name: any) =>
      OnDateBlur(this, this.state.searchCriteria, this.validationData, value, name);

    // Validation Data
    this.validationData = {
      closingDateBegin: new ValidationModel([ValidationType.ValidDate], 'Closing date between From', true),
      closingDateEnd: new ValidationModel([ValidationType.ValidDate], 'Closing date between To', true),
      dealName: new ValidationModel([ValidationType.MaxLength], 'Deal Name', true, { maxLength: 50 }),
      reinvestmentPeriodDate: new ValidationModel([ValidationType.ValidDate], 'Reinvestment period date', true),
    };
    this.linkedColumns = [
      { columnName: 'Deal Nickname', onClick: this.onDNClicked },
      { columnName: 'Director', onClick: this.onDirClicked },
      { columnName: 'Data Analyst', onClick: this.onDAClicked },
      { columnName: 'Global Data Deal', onClick: this.onGDDClicked },
      { columnName: 'Contact Agent', onClick: this.onCAClicked },
    ];
    this.reinvestmentPeriodDateData = [
      { value: '', description: '(None)' },
      { value: 'On or Before', description: 'On or Before' },
      { value: 'On or After', description: 'On or After' },
      { value: 'Equals', description: 'Equals' },
    ];

    let entityStatus = 'A';
    let entityStatusDescription = 'Active';

    if (DEAL_LIST_HOME_PAGE in this.props.searchCriteriaDict && this.props.isBackClicked) {
      entityStatus = this.props.searchCriteriaDict.DEAL_LIST_HOME_PAGE.searchCriteria.entityStatus;
      entityStatusDescription =
        this.props.searchCriteriaDict.DEAL_LIST_HOME_PAGE.searchCriteria.entityStatusDescription;
    }

    this.state = {
      configCode: '',
      dealListData: { data: [], schema: [] },
      editParameters: {},
      entityId: 0,
      isLoading: false,
      searchCriteria: {
        cId: '',
        dealName: '',
        dealType: '',
        entityStatus,
        entityStatusDescription,
        luId: '',
        reinvestmentPeriodDateCompare: '',
        tId: '',
        userId: getUsername(),
      },
      showDataAnalystEdit: false,
      showDirectorEdit: false,
      showSearch: !this.props.isBackClicked || !(DEAL_LIST_HOME_PAGE in this.props.searchCriteriaDict),
      showSettingValueEdit: false,
    };
  }

  /*
   componentDidMount() is invoked immediately after a component is mounted (inserted into the tree)
   */
  public async componentDidMount() {
    if (DEAL_LIST_HOME_PAGE in this.props.searchCriteriaDict && this.props.isBackClicked) {
      await setEntityId(0);
      this.loadData(false);
    } else {
      // Page specific handling
      await setEntityId(0);
      this.onSearchOpen();
    }
  }

  /*
   render() examine this.props and this.state and return one of the following types:
   i.e. React elements, Arrays and fragments, Portals , String and numbers and Booleans or null
   */
  public render() {
    return (
      <PageControl
        pageTitle="Deal List"
        showSearch={this.state.showSearch}
        searchCriteria={this.searchCriteria}
        onSearchClose={this.onSearchClose}
        onSearchOpen={this.onSearchOpen}
        pageWidth={this.state.showSearch ? calcGridInspectorWidth() : undefined}
        inspectorTitle="Search Deals"
        inspectorChildren={
          <InspectorSearch
            showSearchFilter={this.state.showSearch}
            onSearchFilterClose={this.onSearchClose}
            filterHeader="Search Deals"
            onSearchClicked={this.onSearchClicked}
          >
            <table>
              <tbody>
                <TextboxControlRow
                  name="dealName"
                  label="Deal Name"
                  isReadonly={false}
                  isRequired={false}
                  value={this.state.searchCriteria.dealName}
                  isValid={this.validationData.dealName.IsValid}
                  validationMessage={this.validationData.dealName.Message}
                  onChange={this.onChangeWithValidationHandler}
                />
                {RangeStyledComponentRow(
                  <DevXCalendarObject
                    isReadonly={false}
                    isRequired={false}
                    name="closingDateBegin"
                    value={this.state.searchCriteria.closingDateBegin}
                    isValid={this.validationData.closingDateBegin.IsValid}
                    onBlur={this.onDateBlurHandler}
                    validationMessage={this.validationData.closingDateBegin.Message}
                    onChange={this.genericChangeHandler}
                  />,
                  <DevXCalendarObject
                    isReadonly={false}
                    isRequired={false}
                    name="closingDateEnd"
                    value={this.state.searchCriteria.closingDateEnd}
                    isValid={this.validationData.closingDateEnd.IsValid}
                    onBlur={this.onDateBlurHandler}
                    validationMessage={this.validationData.closingDateEnd.Message}
                    onChange={this.genericChangeHandler}
                  />,
                  'Closing date between',
                  'From',
                )}
                <DropDownControlCodeTableRow
                  label="Deal Type"
                  name="dealType"
                  value={this.state.searchCriteria.dealType}
                  codeType="DEAL_TYPE"
                  selectOption="(No Preference)"
                  onChange={this.onChangeHandler}
                />
                <DropdownControlSqlRow
                  selectOption="(No Preference)"
                  displayField="legal_name"
                  valueField="agent_id"
                  name="cId"
                  methodRoute="Sql/DealAgentLookup"
                  methodType={MethodType.Get}
                  parameters="C"
                  label="Collateral Manager"
                  value={this.state.searchCriteria.cId}
                  onChange={this.onChangeHandler}
                />
                <DropdownControlSqlRow
                  selectOption="(No Preference)"
                  displayField="legal_name"
                  valueField="agent_id"
                  name="luId"
                  methodRoute="Sql/DealAgentLookup"
                  methodType={MethodType.Get}
                  parameters="LU"
                  label="Lead Underwriter"
                  value={this.state.searchCriteria.luId}
                  onChange={this.onChangeHandler}
                />
                <DropdownControlSqlRow
                  selectOption="(No Preference)"
                  displayField="legal_name"
                  valueField="agent_id"
                  name="tId"
                  methodRoute="Sql/DealAgentLookup"
                  methodType={MethodType.Get}
                  parameters="T"
                  label="Trustee"
                  value={this.state.searchCriteria.tId}
                  onChange={this.onChangeHandler}
                />
                {InlineRowComponent(
                  <DropdownControl
                    data={this.reinvestmentPeriodDateData}
                    displayField="description"
                    name="reinvestmentPeriodDateCompare"
                    valueField="value"
                    value={this.state.searchCriteria.reinvestmentPeriodDateCompare}
                    onChange={this.onChangeHandler}
                  />,
                  <DevXCalendarObject
                    isReadonly={false}
                    isRequired={false}
                    name="reinvestmentPeriodDate"
                    value={this.state.searchCriteria.reinvestmentPeriodDate}
                    isValid={this.validationData.reinvestmentPeriodDate.IsValid}
                    onBlur={this.onDateBlurHandler}
                    validationMessage={this.validationData.reinvestmentPeriodDate.Message}
                    onChange={this.genericChangeHandler}
                  />,
                  'Reinvestment period date',
                  'dealListReinvestmentDiv',
                )}
                <DropDownControlCodeTableRow
                  label="Entity Status"
                  name="entityStatus;entityStatusDescription"
                  dataTestId="entityStatus"
                  value={this.state.searchCriteria.entityStatus}
                  codeType="ENTITY_STATUS"
                  selectOption="All"
                  isRequired={false}
                  isReadonly={false}
                  onChange={this.genericChangeHandler}
                />
              </tbody>
            </table>
          </InspectorSearch>
        }
      >
        <GridControl
          dataObject={this.state.dealListData}
          enableEditableButton={false}
          isLoading={this.state.isLoading}
          linkedColumns={this.linkedColumns}
        />

        {this.state.showDirectorEdit && (
          <SelectDealVCM params={this.state.editParameters} onClose={this.onModalClose} onSave={this.onSuccess} />
        )}
        {this.state.showDataAnalystEdit && (
          <SelectDealVDA params={this.state.editParameters} onClose={this.onModalClose} onSave={this.onSuccess} />
        )}
        {this.state.showSettingValueEdit && (
          <SettingValueEdit
            configCode={this.state.configCode}
            systemMode={'DEAL'}
            entityId={this.state.entityId}
            onClose={this.onModalClose}
            onSave={this.onSuccess}
          />
        )}
      </PageControl>
    );
  }

  public async loadData(updateGlobalState: boolean = true) {
    this.setState({ isLoading: true });
    this.searchCriteria['Entity Status'] = this.state.searchCriteria.entityStatusDescription;

    const result = await fetchData(ControllerNameEnum.Sql, 'EntityList', MethodType.Post, this.state.searchCriteria);
    await this.setState({ dealListData: result.dataObject, isLoading: false });
    if (updateGlobalState) {
      const searchCriteria = {
        entityStatus: this.state.searchCriteria.entityStatus,
        entityStatusDescription: this.state.searchCriteria.entityStatusDescription,
      };
      await this.props.changeSearchCriteria(searchCriteria, this.searchCriteria);
    }
  }

  // Click event for Deal Nickname column
  private onDNClicked = (dataRow: any, event: any) => {
    event.preventDefault();
    const entityId: string = dataRow.entity_id;
    navigate('/DealSetup/DealInfo/Main/Entity/entityId/' + entityId);
  };

  // Click event for Director column
  private onDirClicked = (dataRow: any, event: any) => {
    event.preventDefault();
    this.setState({
      editParameters: {
        dealName: getTrimmedValue(dataRow.deal_nickname),
        entityId: Number(getTrimmedValue(dataRow.entity_id)),
      },
      showDirectorEdit: true,
    });
  };

  // Click event for Data Analyst column
  private onDAClicked = (dataRow: any, event: any) => {
    event.preventDefault();
    this.setState({
      editParameters: {
        dealName: getTrimmedValue(dataRow.deal_nickname),
        entityId: Number(getTrimmedValue(dataRow.entity_id)),
      },
      showDataAnalystEdit: true,
    });
  };

  // Click event for Global Data Deal column
  private onGDDClicked = (dataRow: any, event: any) => {
    event.preventDefault();
    this.setState({
      configCode: 'global_data_deal',
      entityId: Number(getTrimmedValue(dataRow.entity_id)),
      showSettingValueEdit: true,
    });
  };

  // Click event for Contact Agent column
  private onCAClicked = (dataRow: any, event: any) => {
    event.preventDefault();
    this.setState({
      configCode: 'contact_agent',
      entityId: Number(getTrimmedValue(dataRow.entity_id)),
      showSettingValueEdit: true,
    });
  };

  private onSearchClicked = async () => {
    if (await OnSaveValidation(this, this.state.searchCriteria, this.validationData)) {
      this.setState({
        showSearch: false,
      });
      this.loadData();
    }
  };

  private onSearchClose = () => {
    this.setState({
      showSearch: false,
    });
  };

  private onSearchOpen = () => {
    this.setState({
      showSearch: true,
    });
  };

  private onModalClose = () => {
    this.setState({
      showDataAnalystEdit: false,
      showDirectorEdit: false,
      showSettingValueEdit: false,
    });
  };

  private onSuccess = async () => {
    await this.onModalClose();
    this.loadData(false);
  };
}

const mapStateToProps = (stateFromStore: any) => {
  return {
    isBackClicked: stateFromStore.searchCriteriaRdcr.isBackClicked,
    searchCriteriaDict: stateFromStore.searchCriteriaRdcr.searchCriteriaDict,
  };
};

const mapDispatchActionToProps = (dispatch: any) => {
  return {
    changeSearchCriteria: (searchCriteria: any, searchCriteriaStateObj: any) =>
      dispatch({
        type: SET_SEARCH_CRITERIA,
        data: {
          searchTab: DEAL_LIST_HOME_PAGE,
          searchCriteria,
          searchCriteriaStateObj,
        },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchActionToProps)(DealList);
