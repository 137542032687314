import { navigate } from '@reach/router';
import { MethodType } from 'components/DropDown/MethodType';
import { fetchData, IDataObject, IResponseObject } from 'data/DataConnector';
import React from 'react';
import { PageLink } from 'util/helpers/CommonComponents';
import { getEntityId, rsLookup, toCamel } from 'util/helpers/helperFunctions';

export interface IHeaderBizObjectProps {
  objectCode: string;
  objectIdentifierId: any;
}

const defaultDataObject: IDataObject = { schema: [], data: [] };

const HeaderBizObject = ({ objectCode, objectIdentifierId }: IHeaderBizObjectProps) => {
  const [objectPath, setObjectPath] = React.useState(defaultDataObject);
  React.useEffect(() => {
    fetchData('CommonFunctions', 'CdobizObjectPath', MethodType.Post, {
      objectCode,
      objectIdentifierId,
    }).then(response => {
      setObjectPath(response.dataObject);
    });
  }, [objectCode, objectIdentifierId]);
  return (
    <div style={{ color: 'var(--white)' }}>
      {objectPath.data.map((path: any, index: number) => {
        return (
          <div style={{ display: 'inline' }} key={index}>
            <PageLink onClick={() => navigateTo(path)} title={path.obj_name}>
              {path.descriptor}
            </PageLink>
            {objectPath.data[index + 1] && <span style={{ fontWeight: 'bold' }}> &#11820; </span>}
          </div>
        );
      })}
    </div>
  );
};

const navigateTo = (pathData: any) => {
  Promise.all([
    fetchData('CommonFunctions', 'CdobizObjectPath', MethodType.Post, {
      objectCode: pathData.code,
      objectIdentifierId: pathData.identifier_id,
    }),
    fetchData('CommonFunctions', 'CdobizBusinessObjectGet', MethodType.Post, {
      objectCode: pathData.code,
      objectIdentifierId: pathData.identifier_id,
    }),
  ]).then(([objectPathResponse, businessObjectResponse]: IResponseObject[]) => {
    const url = rsLookup(businessObjectResponse.dataObject.data, 'url_location');
    let navigateToPath = url.replace(new RegExp('_', 'g'), '').replace('.asp', '').replace('//', '/');
    navigateToPath += getQuery(objectPathResponse.dataObject.data);
    const entityId = getEntityId();
    if (entityId) {
      navigateToPath += `/entityId/${entityId}`;
    }
    navigate(navigateToPath);
  });
};

const getQuery = (objectPathData: any[]) => {
  let query = '';
  for (const pathData of objectPathData) {
    if (pathData.identifier && pathData.identifier_id) {
      query += `/${toCamel(pathData.identifier)}/${pathData.identifier_id}`;
    }
  }
  return query;
};

export default HeaderBizObject;
