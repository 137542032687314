import React from 'react';
import { ConvertDouble } from 'util/helpers/helperFunctions';
import { NumberTextBoxStyle } from './Textbox';
import './TextBox.css';

interface IProps {
  name: string;
  value: string;
  precision?: number;
  onChange?: (event: any) => void;
  onChangeWithValidation?: (event: any) => void;
  isReadonly?: boolean;
  style?: any;
  isDisabled?: boolean;
  id?: string;
  theme?: string;
}

interface IState {
  unFormattedValue: string | undefined;
  showFormatted: boolean;
  isValid: boolean;
}

const ToolTipData = '1m - 1,000.00 (thousands) \n1mm - 1,000,000.00 (millions) \n1b - 1,000,000,000.00 (billions)';

let DisabledBoxStyle = {};

class CurrencyTextbox extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isValid: true,
      showFormatted: true,
      unFormattedValue: this.props.value,
    };
    if (this.props.isDisabled) {
      DisabledBoxStyle = {
        color: '#ddddda',
      };
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props !== prevProps) {
      if (this.props.isDisabled) {
        DisabledBoxStyle = {
          color: '#ddddda',
        };
      }
      this.setState({ ...this.state, unFormattedValue: this.props.value });
    }
  }

  public render() {
    let val;
    this.state.showFormatted &&
    this.state.unFormattedValue != null &&
    this.state.unFormattedValue !== '' &&
    isNumber(this.state.unFormattedValue)
      ? (val = ConvertDouble(this.state.unFormattedValue).toLocaleString('en', {
          minimumFractionDigits: this.props.precision ? this.props.precision : 2,
        }))
      : (val = this.state.unFormattedValue);

    return (
      <div style={{ width: '100%', textAlign: 'right' }} className={'currency-textbox'}>
        {this.props.isReadonly ? (
          <span data-testid={this.props.name} style={{ paddingLeft: 6 }}>
            {val}
          </span>
        ) : (
          <input
            data-testid={this.props.name}
            title={ToolTipData}
            name={this.props.name}
            id={this.props.id}
            value={val}
            type="search"
            className={`currencyTextBox ${this.props.theme || ''}`}
            disabled={this.props.isDisabled}
            style={{
              ...NumberTextBoxStyle,
              ...this.props.style,
              ...DisabledBoxStyle,
            }}
            readOnly={this.props.isReadonly}
            onFocus={this.onFocus}
            onChange={this.onChangeHandler}
            onBlur={this.onBlurHandler}
          />
        )}
      </div>
    );
  }

  private onFocus = () => {
    this.setState({ ...this.state, showFormatted: false });
  };

  private onBlurHandler = async () => {
    let num = this.state.unFormattedValue;
    let newNum;
    if (num && !isNumber(num)) {
      const length = num.length;
      num = num.toLowerCase();
      if (num.endsWith('m') && isNumber(num.substr(0, length - 1))) {
        newNum = (Number(num.substr(0, length - 1)) * 1000).toString();
      } else if (num.endsWith('k') && isNumber(num.substr(0, length - 1))) {
        newNum = (Number(num.substr(0, length - 1)) * 1000).toString();
      } else if (num.endsWith('mm') && isNumber(num.substr(0, length - 2))) {
        newNum = (Number(num.substr(0, length - 2)) * 1000000).toString();
      } else if (num.endsWith('b') && isNumber(num.substr(0, length - 1))) {
        newNum = (Number(num.substr(0, length - 1)) * 1000000000).toString();
      } else {
        if (this.props.onChangeWithValidation === undefined) {
          newNum = '';
        } else {
          newNum = num;
        }
      }
    } else {
      newNum = this.state.unFormattedValue;
    }
    await this.setState({
      ...this.state,
      showFormatted: true,
      unFormattedValue:
        newNum != null && newNum !== ''
          ? isNumber(newNum)
            ? ConvertDouble(newNum)
                .toFixed(this.props.precision ? this.props.precision : 2)
                .toString()
            : newNum
          : '',
    });
    const params = {
      value: this.state.unFormattedValue,
      name: this.props.name,
    };
    if (this.props.onChange) {
      this.props.onChange(params);
    } else if (this.props.onChangeWithValidation) {
      this.props.onChangeWithValidation(params);
    }
  };

  private onChangeHandler = (event: any) => {
    this.setState({
      ...this.state,
      unFormattedValue: event.target.value,
    });
  };
}

const isNumber = (val: string) => {
  if (val) {
    val = val.toString().replace(new RegExp(',', 'g'), '');
  }
  return !isNaN(Number(val));
};

export default CurrencyTextbox;
