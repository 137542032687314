import { EditModal } from 'components/Modals/EditModal/EditModal';
import { fetchData, IDataObject, saveData } from 'data/DataConnector';
import React from 'react';
import { PageMessageIconType } from 'util/enums/PageMessageIconTypeEnum';
import { ConvertDouble, getTrimmedValue, getUsername, objectToCamel } from 'util/helpers/helperFunctions';
import { EditBaseClass } from 'views/EditBaseClass';
import { MethodType } from './DropDown/MethodType';
import GridControl, { IGridEditParams, Theme } from './GridControl';
import { onEditorPreparing } from './GridFunctions';
import { DeleteModal } from './Modals/DeleteModal/DeleteModal';
import PageMessageControl from './PageMessageControl/PageMessageControl';
import TextboxControlRow from './TextboxControl/TextboxControlRow';

interface IProps {
  onClose: any;
  onSave: any;
  parameters: any;
}

interface IState {
  isLoading: boolean;
  modalHeader: string;
  dataList: IDataObject;
  spName: string;
  spParameters: string;
  source: string;
  includedLocalStore: boolean;
  gridLoading: boolean;
  showDelete: boolean;
}

class GridLayoutEdit extends EditBaseClass<IProps, IState> {
  private modalRef = React.createRef();
  private gridRef: any = React.createRef();
  private timeout: any;
  private editedRows: any = [];
  private editedRowsObj: any = {};
  private saveInlineChangesReq: boolean = true;
  private editParams: IGridEditParams = {
    columnToDatabaseList: [
      { key: 'Width', value: 'Width' },
      { key: 'Data Type', value: 'Data Type' },
      { key: 'Column Name', value: 'Column Name' },
      { key: 'Hidden', value: 'Hidden' },
    ],
    controllerName: 'crossRef',
    methodName: 'TestMethod',
  };
  private spId: any = 0;
  constructor(props: IProps) {
    super(props);
    this.state = {
      dataList: { schema: [], data: [] },
      gridLoading: false,
      includedLocalStore: false,
      isLoading: true,
      modalHeader: 'Layout Edit',
      showDelete: false,
      source: '',
      spName: '',
      spParameters: '',
    };
  }

  public async componentDidMount() {
    await this.initializeData();
    await this.searchData();
    this.setState({ isLoading: false });
  }

  public render() {
    return (
      <EditModal
        reference={this.modalRef}
        isLoading={this.state.isLoading}
        isModalOpen={true}
        modalHeader={this.state.modalHeader}
        onCloseModal={this.props.onClose}
        onSave={this.onSaveAll}
        onDeleteClicked={this.onDeleteClicked}
        showDeleteButton={true}
      >
        <table style={{ width: 'fit-content' }}>
          <tbody>
            <TextboxControlRow
              label="Source from view source"
              value={this.state.source}
              name="source"
              isReadonly={true}
              isRequired={true}
              onChange={this.genericChangeHandler}
            />
            <TextboxControlRow
              label="Stored Procedure Name"
              value={this.state.spName}
              name="spName"
              isReadonly={true}
              isRequired={true}
              onChange={this.genericChangeHandler}
            />
            <TextboxControlRow
              label="Stored Procedure Parameters"
              value={this.state.spParameters}
              name="spParameters"
              isReadonly={false}
              isRequired={false}
              onChange={this.genericChangeHandler}
            />
          </tbody>
        </table>
        <br />
        {this.state.includedLocalStore && (
          <PageMessageControl
            iconType={PageMessageIconType.Information}
            label={
              'The grid includes changes you made locally. If you dont want to save these changes, please use reset icon to revert the changes'
            }
          />
        )}
        <GridControl
          gridTitle={'Grid Layout'}
          storageKey={'gridLayoutEdit'}
          repaintComponent={this.modalRef}
          isLoading={this.state.gridLoading}
          dataObject={this.state.dataList}
          theme={Theme.Dark}
          showPagination={false}
          editParams={this.editParams}
          onReorder={this.onReorder}
          onEditorPreparing={this.onEditorPreparing}
          dxDataGridProps={{ onRowValidating: this.onRowValidating }}
          reference={this.gridRef}
        />
        {this.state.showDelete && (
          <DeleteModal
            isDtoRequired={true}
            isModalOpen={this.state.showDelete}
            onCloseModal={this.onModalClose}
            onDeleteData={this.props.onClose}
            methodRoute={'/ExpressionBuilder/SpSchemaDel'}
            parameters={{ spId: this.spId }}
            message={`Layout Details of ${this.state.spName}`}
          />
        )}
      </EditModal>
    );
  }

  private onRowValidating = (event: any) => {
    clearTimeout(this.timeout);
    const rowData = { ...event.oldData, ...event.newData };
    if ((rowData['Lookup SP'] || rowData['Lookup Type']) && !rowData['Lookup Code Field']) {
      event.isValid = false;
      event.errorText = '"Lookup Code Field" is required';
      return;
    }
    this.editedRows.push(rowData);
    const oldData = this.editedRowsObj[event.oldData.dataField] || {};
    this.editedRowsObj[event.oldData.dataField] = {
      ...oldData,
      ...event.newData,
    };
    if (this.saveInlineChangesReq) {
      this.timeout = setTimeout(() => {
        this.onSave(this.editedRows);
      }, 1000);
    }
  };

  private onReorder = (event: any) => {
    const keyField = 'Column Name';
    let data = [...this.state.dataList.data];
    const visibleRows = event.component.getVisibleRows();
    const fromIndexKeyField = visibleRows[event.fromIndex].data[keyField];
    const fromIndexData = data.filter((row: any) => row[keyField] === fromIndexKeyField)[0];
    const toIndexKeyField = visibleRows[event.toIndex].data[keyField];
    const toIndexData = data.filter((row: any) => row[keyField] === toIndexKeyField)[0];
    const toIndex = data.indexOf(toIndexData);
    data = data.filter((row: any) => row[keyField] !== fromIndexKeyField);
    data.splice(toIndex, 0, fromIndexData);
    this.setState({
      dataList: {
        ...this.state.dataList,
        data,
      },
    });
  };

  private onEditorPreparing = (event: any) => {
    if (event.parentType !== 'filterRow' && event.dataField === 'Lookup SP Parameter' && !event.row.data['Lookup SP']) {
      event.editorOptions.readOnly = true;
    } else {
      onEditorPreparing(event);
    }
  };

  private initializeData = async () => {
    const sqlCommandText = this.props.parameters.sqlCommandText;
    const spName = sqlCommandText.toLowerCase().split('exec ')[1].split(' ')[0];
    let spParams: string = sqlCommandText.toLowerCase().split('exec ')[1];
    spParams = spParams.substr(spParams.indexOf('@'));
    await this.setState({
      isLoading: false,
      source: sqlCommandText,
      spName,
      spParameters: spParams,
    });
  };

  private searchData = async (createSchema: boolean = true) => {
    this.setState({
      gridLoading: true,
    });
    let includedLocalStore = false;
    const localStorageInfoJson = window.localStorage.getItem(this.props.parameters.storageKey);

    const schemaGetResponse = await fetchData('ExpressionBuilder', 'SpSchemaGet', MethodType.Post, {
      spName: this.state.spName,
      spParams: this.state.spParameters,
    });
    let spParameters = '';
    if (schemaGetResponse.dataObject.data.length > 0) {
      spParameters = schemaGetResponse.dataObject.data[0].sp_params;
      this.spId = schemaGetResponse.dataObject.data[0].sp_id;
    }
    let localStorageInfo: any = { columns: [] };

    if (localStorageInfoJson) {
      localStorageInfo = JSON.parse(localStorageInfoJson);
    }

    const columnData: any = {};
    // There are 3 sources of information; LocalStorage, sp_data_dict and schema of the grid.
    // using information from LocalStorage
    localStorageInfo.columns.forEach((colData: any) => {
      if (colData.dataField) {
        columnData[colData.dataField] = {
          ...colData,
          columnOrder: colData.visibleIndex,
        };
      }
    });
    // using information from sp_data_dict
    schemaGetResponse.dataObject.data.forEach((row: any) => {
      columnData[row.column_name] = {
        ...columnData[row.column_name],
        ...objectToCamel(row),
      };
      if (columnData[row.column_name].columnOrder !== columnData[row.column_name].visibleIndex) {
        columnData[row.column_name].columnOrder = columnData[row.column_name].visibleIndex;
      }
    });
    // using information from schema
    this.props.parameters.schema.forEach((scheme: any) => {
      if (!(scheme.ColumnName in columnData)) {
        columnData[scheme.ColumnName] = {};
      }
      columnData[scheme.ColumnName] = {
        ...columnData[scheme.ColumnName],
        // ...objectToCamel(scheme),
        columnCaption: columnData[scheme.ColumnName].columnCaption || scheme.Caption,
        dataType: columnData[scheme.ColumnName].dataType || scheme.DataTypeName,
        isHidden: columnData[scheme.ColumnName].isHidden || scheme.Hidden,
        isReadonly: columnData[scheme.ColumnName].isReadonly || scheme.IsReadonly,
      };
      if (columnData[scheme.ColumnName].width === scheme.Width) {
        columnData[scheme.ColumnName].width = columnData[scheme.ColumnName].columnWidth
          ? columnData[scheme.ColumnName].columnWidth
          : null;
      } else if (columnData[scheme.ColumnName].width) {
        includedLocalStore = true;
      }
      if (
        getTrimmedValue(scheme.ColumnOrder) !== '' &&
        columnData[scheme.ColumnName].columnOrder !== Number(scheme.ColumnOrder)
      ) {
        includedLocalStore = true;
      }
    });

    let schema: any = [];
    let lookupDataList: any = [];
    if (createSchema) {
      schema.push(this.getSchema('Column Name', { IsReadOnly: true }));
      schema.push(this.getSchema('Caption'));
      schema.push(this.getSchema('Width', { DataTypeName: 'int' }));
      schema.push(this.getSchema('Data Type', { IsReadOnly: true }));
      schema.push(this.getSchema('Hidden', { DataTypeName: 'boolean' }));
      schema.push(this.getSchema('Readonly', { DataTypeName: 'boolean' }));
      schema.push(this.getSchema('Format'));
      schema.push(this.getSchema('Column Order', { DataTypeName: 'int' }));
      schema.push(this.getSchema('Lookup Code Field'));
      schema.push(this.getSchema('Lookup Type'));
      schema.push(this.getSchema('Lookup SP'));
      schema.push(this.getSchema('Lookup SP Parameter'));

      const booleanLookupList = [
        {
          lookup_code: 'null',
          lookup_code_desc: '',
        },
        {
          lookup_code: false,
          lookup_code_desc: 'No',
        },
        {
          lookup_code: true,
          lookup_code_desc: 'Yes',
        },
      ];

      const lookupTypeListResponse = await fetchData('CommonFunctions', 'CdosysLookupTypeList', MethodType.Post, {});
      const lookupTypes = lookupTypeListResponse.dataObject.data.map((record: any) => {
        return {
          lookup_code: record.lookup_type_id,
          lookup_code_desc: record.lookup_type,
        };
      });

      lookupDataList = [
        {
          ColumnName: 'Hidden',
          LookupList: [...booleanLookupList],
        },
        {
          ColumnName: 'Readonly',
          LookupList: [...booleanLookupList],
        },
        {
          ColumnName: 'Lookup Type',
          LookupList: [...lookupTypes],
        },
      ];
    } else {
      const dataList = { ...this.state.dataList };
      schema = dataList.schema;
      lookupDataList = dataList.lookupDataList;
    }

    let data: any = [];
    Object.keys(columnData).forEach((colKey: any) => {
      data.push({
        ...columnData[colKey],
        Caption: columnData[colKey].columnCaption || colKey,
        'Column Name': colKey,
        'Column Order': columnData[colKey].columnOrder,
        'Data Type': columnData[colKey].dataType,
        Format: columnData[colKey].columnFormat,
        Hidden: columnData[colKey].isHidden,
        'Lookup Code Field': columnData[colKey].lookupCodeField,
        'Lookup SP': columnData[colKey].lookupSp,
        'Lookup SP Parameter': columnData[colKey].lookupSpParameter,
        'Lookup Type': columnData[colKey].lookupTypeId,
        Readonly: columnData[colKey].isReadonly,
        Width: columnData[colKey].width,
        columnId: columnData[colKey].columnId,
      });
    });

    data = data.sort((first: any, second: any) => {
      if (first.Hidden < second.Hidden) {
        return -1;
      } else if (first.Hidden === second.Hidden) {
        if (first.columnOrder < second.columnOrder) {
          return -1;
        } else {
          return 1;
        }
      } else {
        return 1;
      }
    });
    this.setState({
      dataList: { schema, data, lookupDataList },
      gridLoading: false,
      includedLocalStore,
      spParameters,
    });
  };

  private getSchema = (columnName: string, extraProps: any = {}) => {
    return {
      BaseColumnName: columnName,
      Caption: columnName,
      ColumnName: columnName,
      ColumnSize: 50,
      DataField: columnName,
      DataTypeName: 'varchar',
      Format: undefined,
      Hidden: false,
      IsReadOnly: false,
      NumericPrecision: 255,
      NumericScale: 255,
      Width: undefined,
      ...extraProps,
    };
  };

  // Save the modified data
  private onSave = async (rowData: any) => {
    const params = {
      SpDataDictDetails: this.createsDataDictDetailString(rowData),
      SpName: this.state.spName,
      SpParams: this.state.spParameters,
      spId: this.spId,
      userId: getUsername(),
    };
    const response = await saveData('ExpressionBuilder', 'SpSchemaPut', params);
    if (!response.hasError) {
      this.editedRows = [];
      this.editedRowsObj = {};
      this.searchData(false);
    }
  };

  private onSaveAll = async () => {
    this.saveInlineChangesReq = false;

    await this.gridRef.current.instance.saveEditData();

    this.setState({ isLoading: true });

    let rowData = [...this.state.dataList.data];

    // Update the updated column order
    rowData = rowData.filter((row: any) => row.Hidden === false);
    rowData.forEach((row: any, index: number) => (row['Column Order'] = index));

    // update inline changes if any
    rowData.forEach((row: any) => {
      const key = row.dataField;
      if (this.editedRowsObj[key]) {
        const data = this.editedRowsObj[key];
        Object.keys(data).forEach((columnName: string) => {
          row[columnName] = data[columnName];
        });
      }
    });

    const params = {
      SpDataDictDetails: this.createsDataDictDetailString(rowData),
      SpName: this.state.spName,
      SpParams: this.state.spParameters,
      spId: this.spId,
      userId: getUsername(),
    };
    const response = await saveData('ExpressionBuilder', 'SpSchemaPut', params);
    if (!response.hasError) {
      this.props.onSave();
    } else {
      this.setState({ isLoading: false });
    }
  };

  private onDeleteClicked = () => this.setState({ showDelete: true });

  private onModalClose = () => this.setState({ showDelete: false });

  // <column_id>;<column_name>;<data_type>;<column_caption>;
  // <column_format>;<column_width>;<column_order>;  --<is_hidden>;<is_readonly>;<lookup_code_field>
  // ;<lookup_type_id>;<lookup_sp>;<lookup_sp_parameter></lookup_sp_parameter>
  private createsDataDictDetailString(sourceData: any) {
    const spDataDictDetails = [];
    for (const data of sourceData) {
      const rowDetails = [];
      rowDetails.push(ConvertDouble(getTrimmedValue(data.columnId)));
      rowDetails.push(getTrimmedValue(data['Column Name']));
      rowDetails.push(getTrimmedValue(data['Data Type']));
      rowDetails.push(getTrimmedValue(data.Caption));
      rowDetails.push(getTrimmedValue(data.Format));
      rowDetails.push(getTrimmedValue(data.Width));
      rowDetails.push(getTrimmedValue(data['Column Order']));
      rowDetails.push(getTrimmedValue(data.Hidden));
      rowDetails.push(getTrimmedValue(data.Readonly));
      rowDetails.push(getTrimmedValue(data['Lookup Code Field']));
      rowDetails.push(getTrimmedValue(data['Lookup Type']));
      rowDetails.push(getTrimmedValue(data['Lookup SP']));
      rowDetails.push(getTrimmedValue(data['Lookup SP Parameter']));
      spDataDictDetails.push(rowDetails.join(';'));
    }
    return spDataDictDetails.join('|');
  }
}

export default GridLayoutEdit;
