import { Location, LocationContext, navigate, Router } from '@reach/router';
import { User } from '@virtus/common/auth/aadWrapper';
import * as GlobalActions from 'actions/globalActions';
import { IS_BACK_CLICKED } from 'actions/SearchCriteria/searchCriteriaActionTypes';
import './App.css';
import Feature from 'app/Feature';
import AuthenticationHandlerGenesis, { AuthenticationRenderHandlerProps } from 'app/AuthenticationHandlerGenesis';
import { MethodType } from 'components/DropDown/MethodType';
import PageLoadingIndicator from 'components/PageLoadingIndicator/PageLoadingIndicator';
import configureHeader from 'config/headerConfig';
import config from 'config/index';
import { RouteConstAccessDenied } from 'constants/RouteConstants';
import { fetchData, setUserName } from 'data/DataConnector';
import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { ControllerNameEnum } from 'util/enums/Enum';
import { getJson } from 'util/helpers/helperFunctions';

const VirtusTheme = React.lazy(() =>
  import('@virtus/components').then(module => ({
    default: module.VirtusTheme,
  })),
);

const AccessDenied = React.lazy(() => import('views/AccessDenied/AccessDenied'));

interface IProps {
  isBackClicked: any;
  dateFormat: any;
  placeholder: any;
  setMenu: any;
  setUser: any;
  setUserFullName: any;
  username: string;
  entityId: number;
  route: any;
  sideMenu: any;
  initMenu: any;
  setUserGroups: any;
}

const App = ({
  isBackClicked,
  dateFormat,
  placeholder,
  setMenu,
  setUser,
  setUserFullName,
  username,
  entityId,
  route,
  sideMenu,
  initMenu,
  setUserGroups,
}: IProps) => {
  useEffect(() => {
    window.onpopstate = async () => {
      isBackClicked(true);
    };
    window.onbeforeunload = () => {
      isBackClicked(true);
    };
    window.onload = async () => {
      isBackClicked(false);
    };
    dateFormat();
    placeholder();
  }, []);

  const headerConfig = configureHeader(route);
  const sideMenuConfig = configureHeader(sideMenu);

  const getUserName = (user: User | any, isValidUser: any) => {
    if (!user || !user.idToken) {
      return '';
    }

    let userId: string = isValidUser?.UserName ?? '';

    if (username !== userId) {
      Promise.all([
        setUser(userId),
        setUserFullName(user.idTokenClaims.given_name + ' ' + user.idTokenClaims.family_name),
        setUserName(userId),
      ]).then(() => {
        initializeMenu(userId);
        setMenu(window.location.pathname);
      });
    }

    return user.idTokenClaims.given_name + ' ' + user.idTokenClaims.family_name;
  };

  const initializeMenu = (userId: string) => {
    if (userId !== undefined && userId !== '') {
      Promise.all([setUserName(userId)]).then(async () => {
        Promise.all([
          fetchData(ControllerNameEnum.CommonFunctions, 'CdosaMenuXmlList', MethodType.Post, {
            userId,
            RecordSetNumber: 1,
          }),
          fetchData(ControllerNameEnum.CommonFunctions, 'CdosaMenuXmlList', MethodType.Post, {
            userId,
            RecordSetNumber: 2,
            entityId: 1,
          }),
          fetchData(ControllerNameEnum.CommonFunctions, 'CdosaUserGroupList', MethodType.Get, [
            { name: 'userId', value: userId },
          ]),
          fetchData('Sql', 'EntityList', MethodType.Post, { userId }),
        ]).then(([defaultPathResponse, dealMenuResponse, userGroups]) => {
          const dealMenu = getJson(dealMenuResponse.dataObject.data);
          if (dealMenu !== undefined && dealMenu.m_level1 && dealMenu.m_level1[0]) {
            initMenu([defaultPathResponse.dataObject.data, dealMenu]);
          } else {
            if (window.location.pathname !== RouteConstAccessDenied) {
              window.location.pathname = RouteConstAccessDenied;
            }
          }
          setUserGroups(userGroups.dataObject.data);
        });
      });
    }
  };

  const logoutUser = (logoutFunction: any) => {
    navigate('/');
    logoutFunction();
  };
  return (
    <Suspense
      fallback={
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'var(--bg)',
          }}
        >
          <PageLoadingIndicator />
        </div>
      }
    >
      <VirtusTheme>
        <AuthenticationHandlerGenesis
          config={config.aad}
          render={({ isAuthenticated, login, logout, user, isValidUser, error }: AuthenticationRenderHandlerProps) => {
            debugger;
            if (error) {
              logoutUser(logout);
              return <></>;
            }
            if (!isValidUser.IsValidUser) {
              logoutUser(logout);
              return <></>;
            }
            const userFullName = getUserName(user, isValidUser);
            return isAuthenticated && headerConfig.routes.subroutes.length > 0 ? (
              <div className="App">
                <Location>
                  {(location: LocationContext) => {
                    return (
                      <>
                        <Feature
                          location={location}
                          isAuthenticated={isAuthenticated}
                          entityId={entityId}
                          username={username}
                          login={login}
                          logout={logout}
                          userFullName={userFullName}
                          logoutUser={logoutUser}
                          isBackClicked={isBackClicked}
                          headerConfig={headerConfig}
                          sideMenuConfig={sideMenuConfig}
                        ></Feature>
                      </>
                    );
                  }}
                </Location>
              </div>
            ) : (
              <React.Fragment>
                {window.location.pathname !== RouteConstAccessDenied && (
                  <div style={{ width: '100%', height: '100%' }}>
                    <PageLoadingIndicator />
                  </div>
                )}
                <Router className={'Router'}>
                  <AccessDenied path={RouteConstAccessDenied} />
                </Router>
              </React.Fragment>
            );
          }}
        />
      </VirtusTheme>
    </Suspense>
  );
};

const mapStateToProps = (stateFromStore: any) => {
  return {
    entityId: stateFromStore.globalRdcr.entityId,
    route: stateFromStore.globalRdcr.route,
    sideMenu: stateFromStore.globalRdcr.sideMenu,
    username: stateFromStore.globalRdcr.username,
  };
};

const mapDispatchActionToProps = (dispatch: any) => {
  return {
    dateFormat: () => dispatch({ type: GlobalActions.GET_DATE_FORMAT }),
    initMenu: (menu: any[]) => dispatch({ type: GlobalActions.INIT_MENU, data: menu }),
    isBackClicked: (isBackClicked: boolean) => dispatch({ type: IS_BACK_CLICKED, data: isBackClicked }),
    placeholder: () => dispatch({ type: GlobalActions.GET_DATE_PLACEHOLDER }),
    setMenu: (menu: string) => dispatch({ type: GlobalActions.SET_MENU, data: menu }),
    setUser: (userId: string) => dispatch({ type: GlobalActions.SET_USER, data: userId }),
    setUserFullName: (userName: string) => dispatch({ type: GlobalActions.SET_USER_FULLNAME, data: userName }),
    setUserGroups: (data: any[]) => dispatch({ type: GlobalActions.SET_USER_GROUPS, data }),
  };
};
export default connect(mapStateToProps, mapDispatchActionToProps)(App);
