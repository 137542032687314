import { ConvertDouble } from 'util/helpers/helperFunctions';

export interface IRoute {
  name: string;
  path: string;
  active?: number;
  subroutes?: IRoute[];
  menuId: string;
  parentMenuId: string;
  page?: string;
  urlImage?: string;
  fullPath?: string;
  context?: string;
  menuType?: string;
  displayOrder?: string;
}

export interface IAPIRoute {
  menu_caption: string;
  url_image?: string;
  url_path: string;
  icon?: any;
  url_target?: string;
  display_order: string;
  sub_menu: string;
  menu_id: string;
  parent_menu_id: string;
  url_page?: string;
  url_full_path?: string;
  menu_type: string;
}

let filterMenuG = false;
export const orderRoutes = (routes: IAPIRoute[]) => {
  if (filterMenuG) {
    routes = routes.filter((route: IAPIRoute) => route.menu_type === 'M');
  }
  return routes.sort(
    (a: any | IAPIRoute, b: any | IAPIRoute) => parseInt(a.display_order, 10) - parseInt(b.display_order, 10),
  );
};

export const parseRoutes = (routes: IAPIRoute[], level: number, context: string = ''): IRoute[] =>
  routes.map((route: IAPIRoute) => {
    let subroutes = (route as any)[`m_level${level}`];
    let orderedSubroutes = null;
    const path = getPath(route);
    const fullPath = getFullPath(route);
    const page = getPage(route);
    const name = getName(route);
    const contextTemp: any = context === '' ? name : `${context} > ${name}`;
    if (subroutes && route.sub_menu === '1') {
      orderedSubroutes = subroutes instanceof Array && subroutes.length ? orderRoutes(subroutes) : [subroutes];
      subroutes = parseRoutes(orderedSubroutes, level + 1, contextTemp);
    }
    return {
      context: contextTemp,
      fullPath,
      hasSubmenu: route.sub_menu === '1',
      icon: route.icon,
      menuId: route.menu_id,
      menuType: route.menu_type,
      name,
      page,
      parentMenuId: route.parent_menu_id,
      path,
      subroutes: subroutes && subroutes.length && route.sub_menu === '1' ? subroutes : undefined,
      target: route.url_target,
    };
  });

export const configureAPIRoutes = (APIroutes: IAPIRoute[], filter?: true): IRoute[] => {
  filterMenuG = filter || false;
  const orderedRoutes = orderRoutes(APIroutes);
  const routes = parseRoutes(orderedRoutes, 2);
  return routes;
};

export const parseTabularRoutes = (routesData: IAPIRoute[]): IRoute[] => {
  const routes: IRoute[] = getSubroutes(routesData, '0');
  return routes;
};

const getSubroutes = (apiRoutes: IAPIRoute[], menuId: string): IRoute[] => {
  const filteredRoutes = apiRoutes.filter(
    (route: IAPIRoute) => ConvertDouble(route.parent_menu_id) === ConvertDouble(menuId),
  );
  const subRoutes: IRoute[] = [];
  for (const apiRoute of filteredRoutes) {
    subRoutes.push(parse(apiRoute, apiRoutes));
  }
  return subRoutes;
};

const parse = (apiRoute: IAPIRoute, apiRoutes: IAPIRoute[]): IRoute => {
  const subRoutes: IRoute[] = getSubroutes(apiRoutes, apiRoute.menu_id);
  return {
    displayOrder: apiRoute.display_order,
    fullPath: getFullPath(apiRoute),
    menuId: apiRoute.menu_id,
    menuType: apiRoute.menu_type,
    name: getName(apiRoute),
    page: getPage(apiRoute),
    parentMenuId: apiRoute.parent_menu_id,
    path: getPath(apiRoute),
    subroutes: subRoutes.length > 0 ? subRoutes : undefined,
    urlImage: apiRoute.url_image,
  };
};

const getPath = (apiRoute: IAPIRoute) => {
  let path = `${
    apiRoute.url_path !== undefined
      ? apiRoute.url_path.replace(new RegExp('_', 'g'), '')
      : apiRoute.menu_caption.indexOf('_Tab') === -1
      ? apiRoute.menu_caption
      : apiRoute.menu_caption.substring(0, apiRoute.menu_caption.indexOf('_Tab'))
  }`;
  path = Number(apiRoute.menu_id) < 0 ? path : path.replace(new RegExp('([^/]+)/', 'g'), '$1_').replace('/', '');
  return path;
};

const getFullPath = (apiRoute: IAPIRoute) => {
  const path = getPath(apiRoute);
  const fullPath = [(apiRoute.url_full_path || '').replace('Apps/', '').replace(new RegExp('_', 'g'), ''), path].join(
    '',
  );
  return fullPath;
};

const getPage = (apiRoute: IAPIRoute) => {
  const page = apiRoute.url_page ? apiRoute.url_page.replace('/', '').replace(new RegExp('.asp', 'ig'), '') : undefined;
  return page;
};

const getName = (apiRoute: IAPIRoute) => {
  let name =
    apiRoute.menu_caption.indexOf('_Tab') === -1
      ? apiRoute.menu_caption
      : apiRoute.menu_caption.substring(0, apiRoute.menu_caption.indexOf('_Tab'));
  name = name.replace(new RegExp('_', 'g'), '');
  return name;
};
