import { navigate, Router } from '@reach/router';
import './App.css';
import DealSelector from 'components/DealSelector';
import { generateRoute, getPath } from 'config/routeConfig';
import React from 'react';
import { getDateTimeKey } from 'util/helpers/helperFunctions';
import DealList from 'views/DealList';

const Header = React.lazy(() => import('@virtus/components/page/Header'));

let routeConfig: any = (
  <Router className={'Router'}>
    <DealList path="/DealList" />
  </Router>
);

const Feature = ({
  location,
  isAuthenticated,
  entityId,
  username,
  login,
  logout,
  userFullName,
  logoutUser,
  isBackClicked,
  headerConfig,
  sideMenuConfig,
}: any) => {
  debugger;
  routeConfig = generateRoute(location.location.pathname);
  return (
    <>
      <Header
        data-testid={location.location.pathname}
        key={getDateTimeKey()}
        misc={isAuthenticated ? <DealSelector entityId={entityId} userId={username} /> : undefined}
        {...headerConfig}
        sideMenuRoutes={sideMenuConfig.routes}
        path={location.location.pathname}
        isLoggedIn={isAuthenticated}
        onLogin={login}
        topRightButtonText={userFullName}
        topRightMenuItems={[
          {
            onClick: () => {
              logoutUser(logout);
            },
            text: 'Logout',
          },
        ]}
        onAppButtonClick={(path: any) => {
          isBackClicked(false);
          navigate(path);
        }}
        onRouteChange={(path: string) => {
          const newPath = getPath(path.slice(0), location.location.pathname, true);
          isBackClicked(false);
          navigate(newPath);
        }}
      />
      {routeConfig}
    </>
  );
};

export default Feature;
