import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const VInternalIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H16V16H0z" />
      <path fill="#FFF" d="M1 12h3v3h2v-5H1v2zm3-8H1v2h5V1H4v3zm6 11h2v-3h3v-2h-5v5zm2-11V1h-2v5h5V4h-3z" />
    </g>
  </SvgIcon>
);
