import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const VExternalIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H16V16H0z" />
      <path fill="#FFF" d="M3 10H1v5h5v-2H3v-3zM1 6h2V3h3V1H1v5zm12 7h-3v2h5v-5h-2v3zM10 1v2h3v3h2V1h-5z" />
    </g>
  </SvgIcon>
);
