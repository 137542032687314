import LoadingIcon, { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import 'components/ActivityIndicator/ActivityIndicator.css';
import React from 'react';

interface IProps {
  theme?: string;
}

const ActivityIndicator = (props: IProps) => {
  return (
    <div className="activity-indicator">
      <LoadingIcon type={LoadingIconType.Virtus} size={LoadingIconSizes.medium} show={true} />
      <p style={{ color: props.theme === 'light' ? 'white' : undefined }}>
        Please wait while your request is being processed...
      </p>
    </div>
  );
};

export default ActivityIndicator;
