import { DialogContent, DialogContentText } from '@mui/material';
import Confirmation from '@mui/icons-material/ContactSupport';
import InfoIcon from '@mui/icons-material/Info';
import DxPopupModal from 'components/hoc/PopupModal/DxPopupModal';
import ModalContent from 'components/hoc/PopupModal/ModalContent';
import { ComponentType } from 'components/hoc/PopupModal/ModalEnum';
import ModalFooter from 'components/hoc/PopupModal/ModalFooter';
import ModalHeader from 'components/hoc/PopupModal/ModalHeader';
import 'components/Modals/ConfirmationModal/ConfirmationModal.css';
import * as ColorPalette from 'components/Themes/theme';
import { ScrollView } from 'devextreme-react';
import React from 'react';
import { PageMessageIconType } from 'util/enums/PageMessageIconTypeEnum';

export interface IProps {
  isModalOpen: boolean;
  modalHeader?: string;
  onConfirm?: any;
  messages: any[];
  onCloseModal: any;
  isLoading?: boolean;
  showDefaultMessage?: boolean;
  showOperationConfirmedBtn?: boolean;
  operationCancelledBtnText?: string;
  iconType?: PageMessageIconType;
  onYesClicked?: any;
  onNoClicked?: any;
}

const confirmationModalStyle = {
  background: ColorPalette.colors.bgDark,
  color: ColorPalette.colors.text,
  fontSize: '14px',
  paddingBottom: '38px',
  width: '100%',
};

export const ConfirmationModal: React.SFC<IProps> = (props: IProps) => {
  return (
    <DxPopupModal
      isModalOpen={props.isModalOpen}
      modalHeader={
        <ModalHeader
          isComponentLoading={props.isLoading ? props.isLoading : false}
          componentHeader={props.modalHeader ? props.modalHeader : 'Confirmation'}
          onCloseClicked={props.onCloseModal}
          onPrintClicked={props.onCloseModal}
          componentType={ComponentType.Confirm}
        />
      }
    >
      <div className="confirmation-modal" style={confirmationModalStyle}>
        <ScrollView
          scrollByContent={true}
          bounceEnabled={false}
          direction={'both'}
          showScrollbar={'always'}
          scrollByThumb={true}
        >
          <DialogContent style={{ padding: '0px' }}>
            <DialogContentText component={'div'} style={{ color: 'white', fontSize: '14px', fontWeight: 'normal' }}>
              <ModalContent isLoading={props.isLoading ? props.isLoading : false}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {props.isLoading === true ? (
                          ''
                        ) : props.iconType === PageMessageIconType.Information ? (
                          <InfoIcon style={{ width: '40px', height: '40px' }} />
                        ) : (
                          <Confirmation style={{ width: '40px', height: '40px' }} />
                        )}
                      </td>
                      <td>
                        <div style={{ paddingLeft: '20px' }}>
                          {props.messages.map((message, index) => (
                            <React.Fragment key={index}>
                              <span style={{ whiteSpace: 'pre-wrap' }}>{message}</span>
                              <br />
                            </React.Fragment>
                          ))}
                          {props.showDefaultMessage === false ? '' : 'Would you like to continue?'}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ModalContent>
            </DialogContentText>
          </DialogContent>
        </ScrollView>
      </div>
      <ModalFooter
        isComponentLoading={props.isLoading ? props.isLoading : false}
        onCancelClicked={props.onCloseModal}
        onOperatioConfirmed={props.onConfirm}
        componentType={props.onYesClicked && props.onNoClicked ? ComponentType.YesOrNo : ComponentType.Confirm}
        showOperationConfirmedBtn={props.showOperationConfirmedBtn}
        operationCancelledBtnText={props.operationCancelledBtnText}
        onYesClicked={props.onYesClicked}
        onNoClicked={props.onNoClicked}
      />
    </DxPopupModal>
  );
};
