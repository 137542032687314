import { withStyles } from 'tss-react/mui';
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';

export interface IProps {
  isValid: boolean;
  message: string;
  uid: string;
}

const HtmlTooltip = withStyles(Tooltip, {
  tooltip: {
    backgroundColor: 'red',
    border: '1px solid #red',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    padding: '10px',
    textAlign: 'center',
    width: 'auto',
  },
});

export const ValidationControl = (props: IProps) => {
  return (
    <React.Fragment>
      <div style={{ width: '16px' }}>
        <HtmlTooltip
          id="validation-control"
          title={
            <React.Fragment>
              <span style={{ color: 'white', fontSize: '14px' }}>{props.message}</span>
            </React.Fragment>
          }
        >
          <WarningIcon
            color="inherit"
            id={props.uid}
            style={{
              display: props.isValid ? 'none' : 'inline',
              width: '16px',
              height: '16px',
            }}
          />
        </HtmlTooltip>
      </div>
    </React.Fragment>
  );
};

export default ValidationControl;
