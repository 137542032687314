import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ToolsIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H16V16H0z" />
      <path
        fill="#FFF"
        d="M15.79 13.078L9.182 6.429c.654-1.68.29-3.653-1.09-5.04C6.642-.074 4.463-.366 2.72.438L5.842 3.58 3.663 5.772.468 2.63c-.871 1.754-.508 3.945.944 5.407 1.38 1.388 3.34 1.753 5.01 1.095l6.609 6.649c.29.292.726.292 1.017 0l1.67-1.68c.363-.293.363-.804.072-1.023z"
      />
    </g>
  </SvgIcon>
);
