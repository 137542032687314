import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const DealMaintenaceIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 1H16V17H0z" transform="translate(0 -1)" />
      <path
        fill="#FFF"
        d="M0 16h5v-2H0v2zM0 4h9V2H0v2zm9 12h7v-2H9v-2H7v6h2v-2zM3 8H0v2h3v2h2V6H3v2zm13 0H7v2h9V8zm-3-2V4h3V2h-3V0h-2v6h2z"
        transform="translate(0 -1)"
      />
    </g>
  </SvgIcon>
);
