import Add from '@mui/icons-material/Add';
import PasteFromClipboard from '@mui/icons-material/Assignment';
import Clear from '@mui/icons-material/Clear';
import Delete from '@mui/icons-material/Delete';
import Properties from '@mui/icons-material/Description';
import Edit from '@mui/icons-material/Edit';
import FolderOpen from '@mui/icons-material/Folder';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import PauseLogo from '@mui/icons-material/Pause';
import ResumeLogo from '@mui/icons-material/PlayArrow';
import Transactions from '@mui/icons-material/Receipt';
import DxDataGrid from '@virtus/components/DxDataGrid';
import { IExtraColumnProps, SchemaType } from '@virtus/components/DxDataGrid/DxDataGrid';
import { DataSourceType } from '@virtus/components/DxDataGrid/utils/mapSchema';
import 'components/GridControl.css';
import { IDataObject, saveData } from 'data/DataConnector';
import {
  Column,
  Editing,
  Export,
  Grouping,
  GroupItem,
  GroupPanel,
  Pager,
  RowDragging,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import { TextArea } from 'devextreme-react/text-area';
import activityIndicatorDark from 'images/loading-dark.gif';
import activityIndicatorLight from 'images/loading-light.gif';
import * as React from 'react';
import { connect } from 'react-redux';
import { IconEnum } from 'util/enums/IconEnum';
import {
  ConvertDouble,
  formatNumberCustom,
  getTrimmedValue,
  localeDateString,
  setGridColumnAlignment,
  showCustomInformationMessage,
  showGridLayoutEdit,
  showUnsavedDataAlert,
} from 'util/helpers/helperFunctions';
import { addDummyColumn, fixBooleanColumns, fixColumnKeyName, generateLink, onEditorPreparing } from './GridFunctions';
import GridLoadingIcon from './Loading/GridLoadingIcon';
import DevXCalendar from './DevXCalendarControl/DevXCalendar';

const DEFAULT_WIDTH = '600px';
const CONTRACT_HEIGHT = '40px';
const PROPERTY = 'property';
const VALUE = 'value';
const DATA_TYPE_NAME = 'data_type_name';
const COLUMN_SIZE = 'column_size';
const NUMERIC_PRECISION = 'numeric_precision';
const NUMERIC_SCALE = 'numeric_scale';
const VERTICAL = 'Vertical';
const TABLE_CURRENCY = 'table_$$$';
const ROW_CURRENCY = 'row_$$$';
const ADD = 'Add';
const CREATE = 'Create';
const EDIT = 'Edit';
const DELETE = 'Delete';
const DELETE_ALL = 'Delete All';
const PASTE_FROM_CLIPBOARD = 'Paste from Clipboard';
const ADD_ICON_COLOR = '#0d83c9';
const EDIT_ICON_COLOR = '#0d83c9';
const DELETE_ICON_COLOR = '#f20000';
const PASTE_FROM_CLIPBOARD_ICON_COLOR = '#7DD358';
const DEFAULT_INSPECTOR_WIDTH = 470;
const DEFAULT_GRID_MARGIN = 10;

export const calcGridInspectorWidth = (inspectorWidth?: number, gridMargin?: number) => {
  const margin =
    (inspectorWidth ? inspectorWidth : DEFAULT_INSPECTOR_WIDTH) + (gridMargin ? gridMargin : DEFAULT_GRID_MARGIN);
  return `calc(100vw - ${margin}px)`;
};

export interface ICustomIcon {
  color?: string;
  onClick: (data: any, event: any) => void;
  iconType: IconEnum;
  tooltip: string;
  hidden?: any;
}

export interface IHeaderButton {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  icon?: any;
}

export interface ILinkedColumn {
  columnName: string;
  onClick: (event: any, data: any) => void;
  condition?: any;
}

export interface IGridEditParams {
  controllerName: string;
  defaultParams?: any;
  methodName: string;
  columnToDatabaseList: IColumnToDataBase[];
  onEditSuccess?: () => void;
  customCheck?: (data: any) => ICustomCheckResult | undefined;
  fetchRowData?: IFetchRowDataParams;
  multiLineColumnsList?: string[];
}

interface ICustomCheckResult {
  errorText: string;
  hasError: boolean;
}

export interface IFetchRowDataParams {
  fetchDataPromise: any;
  responseToDatabaseList: IColumnToDataBase[];
}

interface IColumnToDataBase {
  key: string;
  value: string;
  columnName?: string;
  isRequired?: boolean;
  isBoolean?: boolean;
}

export enum Theme {
  Dark,
  Light,
}

export enum DataFormat {
  money = 'money',
  smalldatetime = 'smalldatetime',
  boolean = 'boolean',
  percent = 'percent',
  dateandtime = 'dateandtime',
}

export interface ICustomCell {
  columnName: string;
  showCustomCell: (rowInfo: any) => boolean;
  cellStyle: any;
}

export interface ICustomCellRenderKeyProps {
  [key: string]: { cellRender: (props: any) => React.ReactNode };
}

export interface IProps {
  loadingMessage?: string;
  showGrouping?: boolean;
  showGroupPanel?: boolean;
  extraColumnProps?: IExtraColumnProps;
  toolbarIcon?: any;
  onRowPrepared?: any;
  onRowClick?: any;
  iconColumnHeader?: string;
  showMultiselect?: boolean;
  editParams?: IGridEditParams;
  hasColSpan?: boolean;
  columnsInfo?: IColumnProps[];
  customIcons?: ICustomIcon[];
  pageSizeOptions?: number[];
  defaultPageSize?: number;
  dataObject: IDataObject;
  isLoading: boolean;
  displayType?: string;
  showPager?: boolean;
  onEdit?: (data: any) => void;
  onDelete?: (data: any) => void;
  headerCustomButtons?: IHeaderButton[];
  customCellRenderKeyProps?: ICustomCellRenderKeyProps;
  linkedColumns?: ILinkedColumn[];
  customCells?: ICustomCell[];
  gridTitle?: string;
  enableEditableButton?: boolean;
  dxDataGridProps?: any;
  children?: any;
  showPagination?: boolean;
  showFilter?: boolean;
  showSearch?: boolean;
  disableAutoPagination?: boolean;
  width?: string;
  gridHeight?: string;
  gridContentHeight?: string;
  gridFixedHeight?: string;
  totalColumnName?: string;
  hideDelete?: any;
  hideEdit?: any;
  gridWidth?: string;
  allowAdd: boolean;
  allowDelete: boolean;
  allowEdit: boolean;
  theme?: Theme;
  columnPrecision?: IColumnPrecision[];
  onContentReady?: any;
  storageKey?: string;
  onSelectionChanged?: (e: any) => void;
  keyExpr?: any;
  selectedRowKeys?: any;
  defaultSelectedRowKeys?: any;
  repaintComponent?: React.RefObject<any>;
  onReorder?: (e: any) => void;
  onCopy?: () => void;
  onPaste?: () => void;
  reference?: React.RefObject<any>;
  loadPanelEnabled?: boolean;
  repaintChangesOnly?: boolean;
  onEditorPreparing?: (e: any) => void;
  singleSelect?: boolean;
  isLoadPanelDisabled?: boolean;
  heightToRemove?: number;
  allowResize?: boolean;
  userGroups: any;
  rowDraggingExtraProps?: any;
  showEditLayout?: boolean;
}

export interface IColumnProps {
  header: string;
  component?: any;
  dataType: DataType;
  dataField?: string;
  extraProps?: any;
  children?: IColumnProps[];
  dataFormat?: DataFormat;
  hasColSpan?: boolean;
}
interface IColumnPrecision {
  columnName: string;
  precision: number | 'nf';
}

export enum DataType {
  Date = 'date',
  Currency = 'currency',
  Number = 'number',
  DateTime = 'dateTime',
  String = 'string',
  Boolean = 'boolean',
}

export const defaultPageSizeOptions: number[] = [10, 15, 20, 25, 50, 100];

interface IState {
  dataFixed: boolean;
  isExpanded: boolean;
}

class GridControl extends React.Component<IProps, IState> {
  private dataObject: IDataObject;
  private enableEditableButton: boolean;
  private isLoading: boolean;
  private displayType: string;
  private pageSizeOptions: number[];
  private pageSizeDefault: number;
  private defaultPageSize: number;
  private onEdit?: (data: any) => void;
  private onDelete?: (data: any) => void;
  private headerCustomButtons?: IHeaderButton[];
  private editButtonsHeader: any[] = [];
  private linkedColumns?: ILinkedColumn[];
  private showPagination: boolean;
  private showFilter: boolean;
  private showSearch: boolean;
  private showEditLayout: boolean;
  private showPager: boolean;
  private disableAutoPagination: boolean;
  private updating: boolean = false;
  private hasEditData: boolean = false;
  private width: string;
  private gridHeight: string;
  private gridWidth: string;
  private gridContentHeight: string;
  private hiddenColumns: string[];
  private columnPrecision?: IColumnPrecision[];
  private storageKey?: string;
  private editParams?: IGridEditParams;
  private extraColumnProps?: any;
  private customCellRenderKeyProps?: ICustomCellRenderKeyProps;
  private isLoadPanelDisabled?: boolean;
  private heightToRemove?: string;
  private allowResize?: boolean;
  private totalRowData: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      dataFixed: false,
      isExpanded: true,
    };
    this.editParams = this.props.editParams;
    this.dataObject = this.props.dataObject;
    this.enableEditableButton = this.props.enableEditableButton !== undefined ? this.props.enableEditableButton : true;
    this.isLoading = this.props.isLoading;
    this.displayType = this.props.displayType || 'Default';
    this.pageSizeOptions = this.props.pageSizeOptions || defaultPageSizeOptions;
    this.pageSizeDefault = this.props.defaultPageSize || 50;
    if (this.props.defaultPageSize !== undefined && this.props.defaultPageSize === -1) {
      this.defaultPageSize = this.dataObject.data.length;
    } else {
      this.defaultPageSize = this.props.defaultPageSize || this.pageSizeDefault;
    }
    this.onEdit = this.props.onEdit;
    this.onDelete = this.props.onDelete;
    this.headerCustomButtons = this.props.headerCustomButtons;
    this.linkedColumns = this.props.linkedColumns;
    this.showPagination = this.props.showPagination !== undefined ? this.props.showPagination : true;
    this.allowResize = this.props.allowResize !== undefined ? this.props.allowResize : false;
    this.showFilter = this.props.showFilter !== undefined ? this.props.showFilter : true;
    this.showSearch = this.props.showSearch !== undefined ? this.props.showSearch : true;
    this.disableAutoPagination =
      this.props.disableAutoPagination !== undefined ? this.props.disableAutoPagination : false;
    this.width = this.props.width || DEFAULT_WIDTH;
    this.gridHeight = '';
    this.gridWidth = this.props.gridWidth ? this.props.gridWidth : '100%';
    this.hiddenColumns = getHiddenColumns(this.dataObject);
    this.gridContentHeight = this.props.gridContentHeight ? this.props.gridContentHeight : 'auto';
    this.columnPrecision = this.props.columnPrecision;
    this.storageKey = this.props.storageKey;
    this.setGridContentHeight(this.gridContentHeight);
    this.isLoadPanelDisabled = this.props.singleSelect ? true : this.props.isLoadPanelDisabled;
    this.totalRowData = [];
    this.showEditLayout = this.props.showEditLayout !== undefined ? this.props.showEditLayout : true;
    this.showPager = this.props.showPager !== undefined ? this.props.showPager : true;
  }

  public render() {
    const removeHeight = this.props.heightToRemove !== undefined ? this.props.heightToRemove : 0;
    this.dataObject = this.props.dataObject;
    this.enableEditableButton = this.props.enableEditableButton !== undefined ? this.props.enableEditableButton : true;
    this.isLoading = this.props.isLoading;
    this.editParams = this.props.editParams;
    this.customCellRenderKeyProps = {
      ...this.customCellRenderKeyProps,
      ...this.props.customCellRenderKeyProps,
    };
    this.extraColumnProps = { ...this.props.extraColumnProps };
    this.displayType = this.props.displayType || 'Default';
    this.pageSizeOptions = this.props.pageSizeOptions || defaultPageSizeOptions;
    this.pageSizeDefault = this.props.defaultPageSize || 50;
    if (this.props.defaultPageSize !== undefined && this.props.defaultPageSize === -1) {
      this.defaultPageSize = this.dataObject.data.length;
    } else {
      this.defaultPageSize = this.props.defaultPageSize || this.pageSizeDefault;
    }
    this.onEdit = this.props.onEdit;
    this.onDelete = this.props.onDelete;
    this.headerCustomButtons = this.props.headerCustomButtons;
    this.linkedColumns = this.props.linkedColumns;
    this.showPagination = this.props.showPagination !== undefined ? this.props.showPagination : true;
    this.showFilter = this.props.showFilter !== undefined ? this.props.showFilter : true;
    this.showSearch = this.props.showSearch !== undefined ? this.props.showSearch : true;
    this.disableAutoPagination =
      this.props.disableAutoPagination !== undefined ? this.props.disableAutoPagination : false;
    this.width = this.props.width || DEFAULT_WIDTH;
    this.heightToRemove = `${removeHeight + 10}px`;
    this.gridHeight = this.props.gridFixedHeight
      ? this.props.gridFixedHeight
      : this.props.gridHeight
      ? `calc(100vh - ${this.props.gridHeight})`
      : `calc(100vh - ${this.heightToRemove})`;
    this.gridWidth = this.props.gridWidth ? this.props.gridWidth : '100%';
    this.hiddenColumns = getHiddenColumns(this.dataObject);
    this.gridContentHeight = this.props.gridContentHeight ? this.props.gridContentHeight : 'auto';
    this.storageKey = this.storageKey || getStorageKey(this.dataObject, this.displayType, this.props.gridTitle);

    this.setExtraColumnPropsForLookUpField();
    this.setGridContentHeight(this.gridContentHeight);
    this.setAndDeleteTotalCol(this.dataObject);
    this.setExtraColumnPropsForColumnOrder();
    this.setMultiLineColEditCell();
    this.setDateEdit();

    if (this.isLoading) {
      if (this.state.dataFixed) {
        this.setState({ dataFixed: false });
      }
      return showLoading(
        this.displayType,
        this.showPagination,
        this.gridHeight,
        this.gridWidth,
        this.width,
        this.gridContentHeight,
        getTrimmedValue(this.props.loadingMessage),
        this.state.isExpanded,
        this.allowResize || false,
        this.props.theme,
      );
    } else if (this.displayType === VERTICAL) {
      return (
        <div
          style={{
            color: 'white',
            width: this.props.width || 'min-content',
            minWidth: this.width,
          }}
        >
          {this.getDataGrid(
            this.dataObject,
            this.displayType,
            this.pageSizeOptions,
            this.defaultPageSize,
            false,
            false,
            this.hiddenColumns,
            this.onEdit,
            this.onDelete,
            this.headerCustomButtons,
            this.linkedColumns,
          )}
        </div>
      );
    } else {
      if (!this.disableAutoPagination && !this.showPagination && this.dataObject.data.length > 20) {
        this.showPagination = true;
        this.defaultPageSize = 20;
        if (this.gridHeight === `calc(100vh - ${this.heightToRemove})`) {
          this.gridHeight = '50vh';
        }
      }
      return (
        <div
          style={getGridStyle(
            this.showPagination,
            this.state.isExpanded,
            this.allowResize || false,
            this.gridHeight,
            this.gridWidth,
          )}
        >
          {this.getDataGrid(
            this.dataObject,
            this.displayType,
            this.pageSizeOptions,
            this.defaultPageSize,
            this.enableEditableButton,
            this.showPagination,
            this.hiddenColumns,
            this.onEdit,
            this.onDelete,
            this.headerCustomButtons,
            this.linkedColumns,
          )}
        </div>
      );
    }
  }

  public shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    if (this.props.dataObject !== nextProps.dataObject) {
      this.totalRowData = [];
      this.storageKey = nextProps.storageKey || this.storageKey;
    }
    return this.props !== nextProps || this.state.isExpanded !== nextState.isExpanded;
  }

  private getDataGrid(
    dataObject: IDataObject,
    displayType: string,
    pageSizeOptions: number[],
    defaultPageSize: number,
    enableEditableButton: boolean,
    showPagination: boolean,
    hiddenColumns: string[],
    onEdit?: (data: any) => void,
    onDelete?: (data: any) => void,
    headerCustomButtons?: IHeaderButton[],
    linkedColumns?: ILinkedColumn[],
  ) {
    const gridDataObject: IDataObject = {
      data: [],
      schema: dataObject.schema,
      schemaType: SchemaType.GENESIS,
    };
    let sdColumns: string[] = [];
    let pageSize: number = defaultPageSize;
    let dataFixed = this.state.dataFixed;
    if (displayType === VERTICAL) {
      const dataRow = dataObject.data[0];
      if (dataRow !== undefined) {
        const tableCurrency = getTableCurrency(dataObject);
        const rowCurrency = getRowCurrency(dataObject);
        const columnWithCurrency = getColumnWithCurrency(dataObject);
        dataObject.schema.forEach((column: any) => {
          let dataValue = dataRow[column.ColumnName];
          let currencyColumn = tableCurrency ? tableCurrency : rowCurrency;
          if (column.ColumnName.indexOf('_') === -1 && !hiddenColumns.includes(column.ColumnName.toString())) {
            if (column.ColumnName !== VALUE) {
              if (column.ColumnName !== PROPERTY) {
                let adjustedColumnName: string = column.ColumnName;
                const dataTypeName = column.DataTypeName.toLowerCase();
                if (column.Caption) {
                  adjustedColumnName = column.Caption;
                }
                const currencyCol: any = column.ColumnName + '_$$$';
                if (columnWithCurrency.indexOf(currencyCol) > -1) {
                  currencyColumn = getTrimmedValue(dataRow[currencyCol]);
                }
                if (dataTypeName === 'money' && currencyColumn !== '') {
                  adjustedColumnName = adjustedColumnName + ' (' + currencyColumn + ')';
                  // column name is adjusted therefore also update it in linkedColumns
                  if (linkedColumns) {
                    const idx = linkedColumns.findIndex(item => item.columnName === column.ColumnName);
                    if (idx !== -1) {
                      linkedColumns[idx].columnName = adjustedColumnName;
                    }
                  }
                } else if (dataTypeName === 'float') {
                  if (this.columnPrecision) {
                    for (const col of this.columnPrecision) {
                      if (col.precision !== 'nf' && column.ColumnName === col.columnName) {
                        if (!isNaN(dataValue)) {
                          dataValue =
                            dataValue !== undefined && dataValue !== null
                              ? ConvertDouble(dataValue).toFixed(col.precision)
                              : dataValue;
                        }
                        break;
                      }
                    }
                  }
                } else if (dataTypeName === 'bit' || dataTypeName === 'tinyint') {
                  dataValue = getTrimmedValue(dataValue) === '1' || dataValue === true ? 'Yes' : 'No';
                } else if (
                  dataTypeName.toLowerCase() === 'smalldatetime' ||
                  dataTypeName.toLowerCase() === 'datetime'
                ) {
                  // Format the date for datetime and smalldatetime data type in vertical grid
                  dataValue = localeDateString(dataValue);
                }

                gridDataObject.data.push({
                  ...dataRow,
                  column_size: column.ColumnSize,
                  data_type_name: column.DataTypeName,
                  format: column.Format,
                  numeric_precision: column.NumericPrecision,
                  numeric_scale: column.NumericScale,
                  property: adjustedColumnName,
                  value: dataValue,
                });
              }
            }
          }
        });

        let columnSchemaPresent: boolean = false;
        gridDataObject.schema.forEach((column: any) => {
          if (column.ColumnName === PROPERTY) {
            columnSchemaPresent = true;
          } else if (column.ColumnName !== VALUE) {
            column.Hidden = true;
          }
        });

        if (!columnSchemaPresent) {
          gridDataObject.schema.push({
            BaseColumnName: PROPERTY,
            ColumnName: PROPERTY,
            ColumnSize: 270,
            DataTypeName: 'varchar',
            NumericPrecision: 255,
            NumericScale: 255,
            Width: 270,
          });
          gridDataObject.schema.push({
            BaseColumnName: VALUE,
            ColumnName: VALUE,
            DataTypeName: 'varchar',
            ColumnSize: 400,
            NumericPrecision: 255,
            NumericScale: 255,
          });
          gridDataObject.schema.push({
            BaseColumnName: DATA_TYPE_NAME,
            ColumnName: DATA_TYPE_NAME,
            ColumnSize: 250,
            DataTypeName: 'varchar',
            NumericPrecision: 255,
            NumericScale: 255,
          });
          gridDataObject.schema.push({
            BaseColumnName: COLUMN_SIZE,
            ColumnName: COLUMN_SIZE,
            DataTypeName: 'varchar',
            ColumnSize: 250,
            NumericPrecision: 255,
            NumericScale: 255,
          });
          gridDataObject.schema.push({
            BaseColumnName: NUMERIC_PRECISION,
            ColumnName: NUMERIC_PRECISION,
            ColumnSize: 250,
            DataTypeName: 'varchar',
            NumericPrecision: 255,
            NumericScale: 255,
          });
          gridDataObject.schema.push({
            BaseColumnName: NUMERIC_SCALE,
            ColumnName: NUMERIC_SCALE,
            ColumnSize: 250,
            DataTypeName: 'varchar',
            NumericPrecision: 255,
            NumericScale: 255,
          });
        }
        this.extraColumnProps = {
          ...this.extraColumnProps,
          value: { ...this.extraColumnProps.value, cssClass: 'wrapped-column' },
        };
        pageSize = gridDataObject.data.length;
      } else {
        pageSize = 1;
        // Handled condition for Vertical grid, where no data is available
        if (headerCustomButtons !== undefined && headerCustomButtons.length > 0) {
          headerCustomButtons.forEach(headerButton => {
            if (headerButton.label.trim() === DELETE || headerButton.label.trim() === EDIT) {
              headerButton.disabled = true;
            }
          });
        }
      }
    } else {
      if (dataObject.schema.length > 0) {
        fixPercentageColumns(dataObject, this.columnPrecision);
        sdColumns = fixSdColumns(dataObject);
      }
      if (dataObject.schema.length > 0 && !dataFixed) {
        fixBooleanColumns(dataObject);
        const tableCurrency: string = getTableCurrency(dataObject);
        const columnWithCurrency = getColumnWithCurrency(dataObject);
        const columnsToChange: IColumnPrecision[] = [];

        // moved code inside one loop
        dataObject.schema.forEach((col: any) => {
          // fix schema column names with period
          if (col.ColumnName.includes('.')) {
            if (col.Caption === '') {
              col.Caption = col.ColumnName;
            }
            col.ColumnName = fixColumnKeyName(col.ColumnName);
          }

          fixCurrencyColumn(col, tableCurrency, columnWithCurrency, dataObject.data, this.extraColumnProps);
          fixFloatColumns(col, columnsToChange, this.columnPrecision);
        });

        dataObject.data.forEach((data: any) => {
          // fix data key names with period
          const dataKeys = Object.keys(data);
          dataKeys.forEach((key: string) => {
            if (key.includes('.')) {
              const newKey = fixColumnKeyName(key);
              data[newKey] = data[key];
              delete data[key];
            }
          });

          fixCurrencyColumnData(data, columnWithCurrency);
          fixFloatColumnData(data, columnsToChange);
        });

        fixHiddenColumns(dataObject, hiddenColumns);
        addDummyColumn(dataObject);

        dataFixed = true;

        this.setState({ dataFixed });
      } else if (this.props.columnsInfo) {
        dataFixed = true;
      }

      gridDataObject.data = dataObject.data;
      if (!showPagination) {
        pageSize = Math.max(dataObject.data.length, 1);
        pageSizeOptions = [];
        pageSizeOptions.push(pageSize);
      }
    }

    const headerButtons: any = [];
    const editButtonsHeader: any = [];
    const editButtons: any = [];

    if (headerCustomButtons !== undefined) {
      headerCustomButtons.forEach(headerButton => {
        if (headerButton.label.trim() === ADD || headerButton.label.trim() === EDIT) {
          if (displayType === VERTICAL && headerButton.label.trim() === ADD && this.props.allowAdd) {
            headerButtons.push({
              location: 'before',
              icon: headerButton.label.toLowerCase(),
              label: '',
              hint: 'Add',
              onClick: headerButton.onClick,
            });
          } else if (displayType === VERTICAL && headerButton.label.trim() === EDIT && this.props.allowEdit) {
            headerButtons.push({
              location: 'before',
              icon: headerButton.label.toLowerCase(),
              label: '',
              hint: 'Edit',
              onClick: headerButton.onClick,
            });
          } else if (headerButton.label.trim() === ADD && this.props.allowAdd) {
            editButtonsHeader.push({
              color: ADD_ICON_COLOR,
              iconType: IconEnum.Add,
              onClick: () => {
                if (this.checkHasEditData()) {
                  return;
                }
                headerButton !== undefined ? headerButton.onClick() : voidFunction();
              },
              tooltip: 'Add',
            });
          }
        } else if (headerButton.label.trim() === DELETE && this.props.allowDelete) {
          headerButtons.push({
            location: 'before',
            icon: 'trash',
            label: '',
            hint: 'Delete',
            onClick: headerButton.onClick,
          });
        } else if (displayType !== VERTICAL && headerButton.label.trim() === DELETE_ALL && this.props.allowDelete) {
          if (this.dataObject.data.length > 0) {
            editButtonsHeader.push({
              color: DELETE_ICON_COLOR,
              iconType: IconEnum.Delete,
              onClick: headerButton.onClick,
              tooltip: 'Delete All',
            });
          }
        } else if (
          displayType !== VERTICAL &&
          headerButton.label.trim() === PASTE_FROM_CLIPBOARD &&
          this.props.allowAdd
        ) {
          editButtonsHeader.push({
            color: PASTE_FROM_CLIPBOARD_ICON_COLOR,
            iconType: IconEnum.PasteFromClipboard,
            onClick: headerButton.onClick,
            tooltip: 'Paste from Clipboard',
          });
        } else if (this.props.allowAdd) {
          const buttonIcon: string =
            headerButton.label.startsWith(ADD) || headerButton.label.startsWith(CREATE)
              ? 'add'
              : headerButton.icon != null
              ? headerButton.icon
              : 'preferences';
          headerButtons.push({
            location: 'after',
            icon: buttonIcon,
            label: headerButton.label,
            onClick: headerButton.onClick,
          });
        }
      });
    }
    if (dataFixed) {
      this.editButtonsHeader = editButtonsHeader;
      if (onEdit !== undefined && this.props.allowEdit) {
        editButtons.push({
          color: EDIT_ICON_COLOR,
          hidden: (cellInfo: any) => (this.props.hideEdit ? this.props.hideEdit(cellInfo) : false),
          iconType: IconEnum.Edit,
          onClick: (cellInfo: any) => {
            if (this.checkHasEditData()) {
              return;
            }
            onEdit !== undefined ? onEdit(cellInfo) : voidFunction();
          },
          tooltip: 'Edit',
        });
      }
      if (onDelete !== undefined && this.props.allowDelete) {
        editButtons.push({
          color: DELETE_ICON_COLOR,
          hidden: (cellInfo: any) => (this.props.hideDelete ? this.props.hideDelete(cellInfo) : false),
          iconType: IconEnum.Delete,
          onClick: (cellInfo: any) => {
            if (this.checkHasEditData()) {
              return;
            }
            onDelete !== undefined ? onDelete(cellInfo) : voidFunction();
          },
          tooltip: 'Delete',
        });
      }
      if (this.props.customIcons && this.props.customIcons.length > 0) {
        this.props.customIcons.forEach((icon: ICustomIcon) => {
          editButtons.push({
            color: icon.color ? icon.color : '#0d83c9',
            hidden: (cellInfo: any) => (icon.hidden ? icon.hidden(cellInfo) : false),
            iconType: icon.iconType,
            onClick: (cellInfo: any, event: any) => {
              icon.onClick(cellInfo, event);
            },
            tooltip: icon.tooltip,
          });
        });
      }
    }
    enableEditableButton =
      this.props.enableEditableButton !== undefined
        ? enableEditableButton
        : displayType !== VERTICAL && editButtons.length > 0;
    const buttonColumnWidth: number = Math.max(editButtons.length * 24, this.editButtonsHeader.length * 24, 28);
    const moreButtonActions: any = [];
    if (dataObject.sqlCommandText !== undefined) {
      moreButtonActions.push({
        label: 'View Source',
        icon: 'info',
        onClick: () => showCustomInformationMessage(this.dataObject.sqlCommandText || '', 'Source Information', true),
      });
      if (
        this.displayType !== VERTICAL &&
        this.props.userGroups.filter((group: any) => group.groupId === 9 || group.groupId === 1).length &&
        this.showEditLayout
      ) {
        moreButtonActions.push({
          icon: 'edit',
          label: 'Edit Layout',
          onClick: () =>
            showGridLayoutEdit({
              sqlCommandText: dataObject.sqlCommandText,
              schema: dataObject.schema,
              storageKey: this.storageKey,
            }),
        });
      }
    }

    if (pageSize === 0 && this.isLoading) {
      return showLoading(
        displayType,
        showPagination,
        this.gridHeight,
        this.gridHeight,
        this.width,
        this.gridContentHeight,
        getTrimmedValue(this.props.loadingMessage),
        this.state.isExpanded,
        this.allowResize || false,
        this.props.theme,
      );
    } else {
      if (showPagination) {
        const groupPanelCount = getGroupPanelCount(dataObject.data, this.props.extraColumnProps);
        pageSizeOptions = generatePageSizeOptions(pageSizeOptions, dataObject.data.length + groupPanelCount);
        if (this.pageSizeDefault === -1) {
          pageSize = pageSize + groupPanelCount;
        }
        if (pageSize > pageSizeOptions[pageSizeOptions.length - 1]) {
          pageSize = pageSizeOptions[pageSizeOptions.length - 1];
        }
      }
      setPageSize(pageSizeOptions, pageSize, this.storageKey, this.pageSizeDefault);
      removeSelectedRows(this.storageKey);
      let resetButton: any = {};

      if (this.storageKey) {
        resetButton = {
          location: 'after',
          options: {
            hint: 'Reset',
            icon: 'refresh',
            onClick: this.resetGrid,
          },
          widget: 'dxButton',
        };
      }

      const infoText: string = 'Page {0} of {1} ({2} record' + (dataObject.data.length > 1 ? 's)' : ')');
      const isButtonColumnVisible = { visible: false };
      const extraProps: any = {};
      if (this.props.showMultiselect) {
        if (this.props.keyExpr) {
          extraProps.keyExpr = this.props.keyExpr;
        }
        if (this.props.selectedRowKeys) {
          extraProps.selectedRowKeys = this.props.selectedRowKeys;
        }
        extraProps.defaultSelectedRowKeys = this.props.defaultSelectedRowKeys;
      }
      let addBefore: any = [];
      if (this.allowResize && displayType !== VERTICAL) {
        addBefore = [
          ...addBefore,
          {
            location: 'before',
            options: {
              hint: this.state.isExpanded ? 'Hide' : 'Expand',
              icon: this.state.isExpanded ? 'chevronup' : 'chevrondown',
              onClick: () => this.setState({ isExpanded: !this.state.isExpanded }),
            },
            widget: 'dxButton',
          },
        ];
      }
      if (this.props.toolbarIcon) {
        addBefore = [
          ...addBefore,
          {
            location: 'before',
            options: {
              ...this.props.toolbarIcon,
            },
            widget: 'dxButton',
          },
        ];
      }
      if (this.props.onCopy) {
        addBefore = [
          ...addBefore,
          {
            location: 'after',
            options: {
              hint: 'Copy',
              icon: 'copy',
              onClick: this.props.onCopy,
            },
            widget: 'dxButton',
          },
        ];
      }
      if (this.props.onPaste) {
        addBefore = [
          ...addBefore,
          {
            location: 'after',
            options: {
              hint: 'Paste',
              icon: 'paste',
              onClick: this.props.onPaste,
            },
            widget: 'dxButton',
          },
        ];
      }

      if (displayType !== VERTICAL) {
        gridDataObject.schema.forEach((column: any) => {
          if (column.DataTypeName === 'boolean') {
            column.DataTypeName = 'bool';
          }
        });
      }

      return (
        <DxDataGrid
          useDarkTheme={this.props.theme === Theme.Dark ? true : false}
          ref={this.props.reference}
          extraColumnProps={this.extraColumnProps}
          onSelectionChanged={this.props.onSelectionChanged}
          dataSource={gridDataObject}
          dataSourceType={DataSourceType.VIRTUS}
          displayType={this.displayType}
          repaintChangesOnly={this.props.repaintChangesOnly}
          dxDataGridComponentsProps={{ ColumnChooser: { search: { enabled: true } } }}
          dxDataGridProps={{
            columnResizingMode: 'widget',
            focusedRowEnabled: !this.props.showMultiselect,
            headerFilter: {},
            // onCellPrepared: this.props.totalColumnName != null ? (event: any) => formatTotalRow(event, getTrimmedValue(this.props.totalColumnName)) : undefined,
            onCellPrepared: sdColumns.length
              ? (event: any) => {
                  if (sdColumns.length) {
                    setGridColumnAlignment(event, sdColumns, 'right');
                  }
                }
              : undefined,
            onContentReady: (event: any) => {
              if (this.props.repaintComponent && this.props.repaintComponent.current) {
                (this.props.repaintComponent as any).current.instance.repaint();
              }
              this.hasEditData = event.component.hasEditData();
              if (this.updating && !this.hasEditData) {
                this.updating = false;
                if (this.editParams && this.editParams.onEditSuccess) {
                  this.editParams.onEditSuccess();
                }
              }
              if (this.props.onContentReady) {
                this.props.onContentReady(event);
              }
            },
            onEditorPreparing: this.props.onEditorPreparing || onEditorPreparing,
            onRowClick: this.props.onRowClick,
            onRowPrepared: this.props.onRowPrepared,
            onRowValidating: this.onSaveRowData,
            toolbarButtons: {
              addAfter: [resetButton],
              addBefore,
            },
            remove: [],

            ...extraProps,
            ...this.props.dxDataGridProps,
          }}
          indicatorSrc={this.props.theme === Theme.Dark ? activityIndicatorLight : activityIndicatorDark}
          loadPanelEnabled={!this.isLoadPanelDisabled}
          showColumnLines={true}
          showRowLines={true}
          showColumnHeaders={displayType === VERTICAL ? false : true}
          showPaging={showPagination}
          showGrouping={false}
          showSearch={displayType === VERTICAL ? false : this.showSearch}
          showFilter={displayType === VERTICAL ? false : this.showFilter}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          showRefresh={false /*(displayType === VERTICAL) ? false : true*/}
          showColumnChooser={false}
          gridTitle={this.props.gridTitle}
          disableStateStoring={true}
          exportFileName={'GenesisExport'}
          customCellRenderKeyProps={this.customCellRenderKeyProps}
          linkedColumns={linkedColumns}
          headerButtons={headerButtons}
          moreButtonActions={moreButtonActions}
          storageKey={''}
        >
          {this.props.showGrouping && <Grouping autoExpandAll={true} />}
          {this.props.showGroupPanel && <GroupPanel visible={true} />}
          {this.editParams && this.props.allowEdit && (
            <Editing mode="batch" startEditAction={'dblClick'} selectTextOnEditStart={true} allowUpdating={true} />
          )}
          <StateStoring
            enabled={true}
            storageKey={this.storageKey}
            type={'custom'}
            customSave={(state: any) => customSave(this.storageKey, state, this.dataObject.sqlCommandText)}
            customLoad={() => customLoad(this.storageKey, this.dataObject.sqlCommandText)}
          />
          {displayType !== VERTICAL && (editButtons.length > 0 || this.editButtonsHeader.length > 0) ? (
            <Column
              // cssClass={this.props.theme === Theme.Dark ? 'dx-column-buttons' : ''}
              name="buttonColumn"
              allowExporting={false}
              allowGrouping={false}
              allowReordering={false}
              allowSorting={false}
              fixed={true}
              fixedPosition="left"
              width={buttonColumnWidth}
              minWidth={35}
              cellRender={(cellData: any): any => generateButtons(cellData, editButtons, isButtonColumnVisible)}
              headerCellRender={() => generateHeaderButtons(this.editButtonsHeader, this.props)}
            />
          ) : (
            <></>
          )}
          {this.props.hasColSpan && this.props.columnsInfo ? (
            this.props.columnsInfo.map((col: IColumnProps, index: any) => {
              return (
                <Column
                  key={index}
                  caption={col.header}
                  allowFiltering={false}
                  alignment={'center'}
                  dataField={col.dataField}
                  dataType={col.dataType}
                  cellRender={col.component}
                  {...col.extraProps}
                >
                  {/* Get Column wil Recursively call to get nexted columns */}
                  {this.props.columnsInfo ? col.children && this.getColumns(col.hasColSpan, col.children) : <></>}
                </Column>
              );
            })
          ) : this.props.columnsInfo ? (
            this.props.columnsInfo.map((col: IColumnProps, index: any) => {
              return (
                <Column
                  key={index}
                  caption={col.header}
                  allowFiltering={true}
                  dataField={col.dataField}
                  dataType={col.dataType}
                  cellRender={(cellData: any) => this.getFormattedValue(col, cellData)}
                  {...col.extraProps}
                />
              );
            })
          ) : (
            <></>
          )}
          {this.showPager && displayType !== VERTICAL && dataObject.data.length > 0 ? (
            <Pager
              allowedPageSizes={pageSizeOptions}
              showPageSizeSelector={pageSizeOptions.length > 1 ? true : false}
              showInfo={true}
              infoText={infoText}
              visible={true}
            />
          ) : (
            <></>
          )}

          <Selection
            mode="multiple"
            showCheckBoxesMode={this.props.showMultiselect ? 'always' : 'none'}
            allowSelectAll={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          {this.props.onReorder ? (
            <RowDragging
              allowReordering={true}
              onReorder={(event: any) => {
                if (this.checkHasEditData()) {
                  return;
                }
                if (this.props.onReorder !== undefined) {
                  this.props.onReorder(event);
                }
              }}
              showDragIcons={false}
              {...this.props.rowDraggingExtraProps}
            />
          ) : (
            <></>
          )}
          <Summary calculateCustomSummary={calculateCustomSummary}>
            <GroupItem column="Test Name" summaryType="custom" name="custom-count" />
            <GroupItem column="Display Order" summaryType="min" name="DisplayOrder" />
          </Summary>
          {this.props.singleSelect && (
            <Column
              alignment="center"
              width="30px"
              caption=""
              fixedPosition="left"
              fixed={true}
              cellRender={this.getRadioButtons}
            />
          )}
          {getTrimmedValue(this.props.totalColumnName) !== '' && this.totalRowData && this.totalRowData.length > 0 && (
            <Summary calculateCustomSummary={this.calculateTotalRow} recalculateWhileEditing={true}>
              {this.props.dataObject.schema.length > 0
                ? this.totalRowData.map((totalRow: any, index: any) => {
                    return (
                      this.props.dataObject.schema &&
                      this.props.dataObject.schema.map((column: any) => {
                        return this.getToolItems(column.ColumnName, column.DataTypeName, index);
                      })
                    );
                  })
                : this.props.hasColSpan && this.props.columnsInfo
                ? this.totalRowData.map((totalRow: any, index: any) => {
                    return (
                      this.props.columnsInfo &&
                      this.props.columnsInfo.map(col => {
                        return (
                          col.children &&
                          col.children.map((child: any) => {
                            return this.getToolItems(child.dataField, child.DataFormat, index);
                          })
                        );
                      })
                    );
                  })
                : this.totalRowData.map((totalRow: any, index: any) => {
                    return (
                      this.props.columnsInfo &&
                      this.props.columnsInfo.map((col: any) => {
                        return this.getToolItems(col.dataField, col.DataFormat, index);
                      })
                    );
                  })}
            </Summary>
          )}
          <SortByGroupSummaryInfo summaryItem="min" />
          {this.props.children}
        </DxDataGrid>
      );
    }
  }

  private getColumns = (hasColSpan: boolean = false, columnInfo: IColumnProps[]) => {
    if (hasColSpan) {
      return columnInfo.map((col: IColumnProps, index: any) => {
        return (
          <Column
            key={index}
            caption={col.header}
            allowFiltering={false}
            alignment={'center'}
            dataField={col.dataField}
            dataType={col.dataType}
            cellRender={col.component}
            {...col.extraProps}
          >
            {col.children && this.getColumns(col.hasColSpan, col.children)}
          </Column>
        );
      });
    } else {
      return columnInfo ? (
        columnInfo.map((col: IColumnProps, index: any) => {
          return (
            <Column
              key={index}
              caption={col.header}
              allowFiltering={true}
              dataField={col.dataField}
              dataType={col.dataType}
              cellRender={(cellData: any) => this.getFormattedValue(col, cellData)}
              {...col.extraProps}
            />
          );
        })
      ) : (
        <></>
      );
    }
  };

  private getRadioButtons = (cellData: any) => {
    return (
      <input
        type="radio"
        id={'radioId'}
        name="selectGrid"
        checked={
          this.props.selectedRowKeys.length > 0
            ? this.props.selectedRowKeys[0] === cellData.data[this.props.keyExpr]
            : false
        }
        value={cellData.data[this.props.keyExpr]}
        onClick={undefined}
      />
    );
  };

  private calculateTotalRow = (options: any) => {
    if (options) {
      const optionRow = options.name.split(';');
      if (optionRow.length === 2) {
        const name = optionRow[0];
        const index = Number(optionRow[1]);
        options.totalValue =
          name === this.props.totalColumnName
            ? 'Total:'
            : getTrimmedValue(this.totalRowData[index][name]) !== '' &&
              getTrimmedValue(this.totalRowData[index][name]).toLowerCase() !== 'null'
            ? this.totalRowData[index][name]
            : '';
      }
    }
  };

  private setExtraColumnPropsForColumnOrder() {
    this.dataObject.schema.forEach((schemaObj: any) => {
      if (getTrimmedValue(schemaObj.ColumnOrder) !== '') {
        this.extraColumnProps[schemaObj.ColumnName] = {
          ...this.extraColumnProps[schemaObj.ColumnName],
          visibleIndex: schemaObj.ColumnOrder,
        };
      }
    });
  }

  private setExtraColumnPropsForLookUpField() {
    const extraColumnProps: any = {};
    const customCellRenderKeyProps: any = {};
    if (this.dataObject.lookupDataList) {
      this.dataObject.lookupDataList.forEach((element: any) => {
        extraColumnProps[getTrimmedValue(element.ColumnName)] = {
          lookup: {
            allowClearing: true,
            dataSource: element.LookupList,
            displayExpr: 'lookup_code_desc',
            valueExpr: 'lookup_code',
          },
        };
        customCellRenderKeyProps[getTrimmedValue(element.ColumnName)] = {
          cellRender: (cellData: any) => generateLink(cellData, this.props.linkedColumns),
        };
      });
      this.extraColumnProps = { ...extraColumnProps, ...this.extraColumnProps };
      this.customCellRenderKeyProps = {
        ...customCellRenderKeyProps,
        ...this.customCellRenderKeyProps,
      };
    }
  }

  private onSaveRowData = (event: any) => {
    let errorCol: any = [];
    if (this.editParams) {
      const rowData = { ...event.oldData, ...event.newData };
      let customCheckResult: ICustomCheckResult = {
        hasError: false,
        errorText: '',
      };
      this.editParams.columnToDatabaseList.forEach((data: IColumnToDataBase) => {
        if (data.isRequired && getTrimmedValue(rowData[data.key]) === '') {
          errorCol = [...errorCol, data.columnName ? data.columnName : data.key];
        }
      });
      if (this.editParams.customCheck != null) {
        const customCheckResultTemp = this.editParams.customCheck(rowData);
        if (customCheckResultTemp && customCheckResultTemp.hasError) {
          customCheckResult = customCheckResultTemp;
        }
      }
      if (errorCol.length > 0) {
        event.isValid = false;
        event.errorText =
          errorCol.length > 1
            ? 'Values for ' + errorCol.join(' , ') + ' are required.'
            : 'Value for ' + errorCol[0] + ' is required. ';
      } else if (customCheckResult.hasError) {
        event.isValid = false;
        event.errorText = customCheckResult.errorText;
      } else {
        let saveParam = { ...this.editParams.defaultParams };
        this.editParams.columnToDatabaseList.forEach((data: IColumnToDataBase) => {
          saveParam = data.isBoolean
            ? {
                ...saveParam,
                [data.value]: rowData[data.key] === 1 || rowData[data.key] === true ? 1 : 0,
              }
            : { ...saveParam, [data.value]: rowData[data.key] };
        });
        if (!this.updating) {
          this.updating = true;
        }
        const result = this.saveRowData(saveParam, rowData);
        event.promise = result.then((data: any) => {
          if (data && data.hasError) {
            event.isValid = false;
            event.errorText = data.errorMessage;
          }
        });
      }
    }
  };

  private async saveRowData(saveParam: any, rowData: any) {
    if (this.editParams) {
      if (this.editParams.fetchRowData) {
        const response = await this.editParams.fetchRowData.fetchDataPromise(rowData);
        if (response.dataObject.data.length > 0) {
          this.editParams.fetchRowData.responseToDatabaseList.forEach((data: IColumnToDataBase) => {
            saveParam = data.isBoolean
              ? {
                  ...saveParam,
                  [data.value]:
                    response.dataObject.data[0][data.key] === 1 || response.dataObject.data[0][data.key] === true
                      ? 1
                      : 0,
                }
              : {
                  ...saveParam,
                  [data.value]: response.dataObject.data[0][data.key],
                };
          });
        }
      }
      const result = await saveData(this.editParams.controllerName, this.editParams.methodName, saveParam, false);
      return result;
    }
  }

  private getToolItems(columnName: string, dataType: string, index: number) {
    return (
      <TotalItem
        cssClass={'dx-total-row'}
        key={`${columnName}${index}`}
        name={`${columnName};${index}`}
        summaryType="custom"
        displayFormat={'{0}'}
        showInColumn={columnName}
        valueFormat={
          dataType !== 'money'
            ? 'Number'
            : {
                precision: 2,
                type: 'fixedPoint',
              }
        }
        alignment={this.getTotalColumnAlignment(dataType)}
      />
    );
  }

  private getTotalColumnAlignment(dataType: any) {
    return getTrimmedValue(dataType).toLowerCase() === 'string' ||
      getTrimmedValue(dataType).toLowerCase() === 'char' ||
      getTrimmedValue(dataType).toLowerCase() === 'varchar'
      ? 'left'
      : 'right';
  }

  private getHeader = (col: any) => {
    if (col.headerComponent) {
      return col.headerComponent();
    } else {
      return <span>{col.header}</span>;
    }
  };

  private getFormattedValue(col: any, cellData: any) {
    if (col.component) {
      return col.component(cellData);
    } else {
      const cellValue = cellData.data[col.dataField];
      let formattedValue: any = cellValue;
      switch (col.dataFormat && col.dataFormat.toLowerCase()) {
        case 'tinyint':
        case 'boolean':
        case 'bit':
          formattedValue = 'No';
          if (cellValue === 1 || cellValue === true) {
            formattedValue = 'Yes';
          } else if (cellValue === 0 || cellValue === false) {
            formattedValue = 'No';
          } else {
            formattedValue = cellValue;
          }
          break;
        case 'datetime':
        case 'smalldatetime':
          // Rely on browser for date formatting
          const date = Date.parse(cellValue);
          formattedValue = !isNaN(date) ? new Date(cellValue).toLocaleDateString() : cellValue;
          break;
        case 'dateandtime':
          const datetime = Date.parse(cellValue);
          formattedValue = !isNaN(datetime) ? new Date(cellValue).toLocaleString() : cellValue;
          break;
        case 'money':
          formattedValue = formatNumberCustom(cellValue, 2);
          break;
        case 'percent':
          formattedValue = isNaN(cellValue) ? `${cellValue.toLocaleString()}%` : `${percentFormat(cellValue)}%`;
          break;
        default:
          formattedValue = cellValue;
          break;
      }
      return <span>{formattedValue}</span>;
    }
  }

  private setGridContentHeight(gridContentHeight: any) {
    const root = document.documentElement;
    if (gridContentHeight !== undefined) {
      root.style.setProperty('--gridContentHeight', gridContentHeight);
    }
  }

  private setAndDeleteTotalCol(dataObject: any) {
    const totalColumnName = getTrimmedValue(this.props.totalColumnName);
    const isColumnExist = dataObject.schema.filter((schema: any) => schema.ColumnName === totalColumnName)[0];
    if (totalColumnName && isColumnExist) {
      dataObject.data = dataObject.data.filter((item: any) => {
        if (
          this.props.totalColumnName &&
          (getTrimmedValue(item[totalColumnName]) === '' || getTrimmedValue(item[totalColumnName]) === '0')
        ) {
          this.totalRowData.push(item);
          return false;
        } else {
          return true;
        }
      });
    }
  }

  private setMultiLineColEditCell() {
    if (this.editParams && this.editParams.multiLineColumnsList) {
      this.editParams.multiLineColumnsList.forEach((columnName: string) => {
        this.extraColumnProps[columnName] = {
          ...this.extraColumnProps[columnName],
          editCellComponent: textAreaEditCellComponent,
        };
      });
    }
  }

  private setDateEdit() {
    if (this.editParams && this.editParams.columnToDatabaseList) {
      this.dataObject.schema.forEach((schemaObj: any) => {
        const dataTypeName = schemaObj.DataTypeName.toLowerCase();
        if (dataTypeName === 'smalldatetime' || dataTypeName === 'datetime') {
          this.extraColumnProps[schemaObj.ColumnName] = {
            ...this.extraColumnProps[schemaObj.ColumnName],
            editCellComponent: this.dateColComponent,
          };
          schemaObj.Width = 130;
        }
      });
    }
  }

  private dateColComponent = (event: any) => {
    if (event) {
      return (
        <DevXCalendar
          value={event.data.value ? event.data.value : undefined}
          onChange={(value: any) => {
            event.data.setValue(value);
          }}
          style={{ width: '120px' }}
          isRequired={true}
          hideValidationControl={true}
          theme={Theme.Light}
        />
      );
    }
  };

  private checkHasEditData() {
    if (this.hasEditData) {
      showUnsavedDataAlert('');
      return true;
    }
    return false;
  }

  private resetGrid = () => {
    resetStore(this.storageKey);
    this.forceUpdate();
  };
}

const percentFormat = (value: number) => {
  if (!value) {
    return '0';
  }
  if (Number.isInteger(Number(value))) {
    return Number(value);
  }
  return Number(value).toFixed(3);
};

const getHiddenColumns = (dataObject: IDataObject) => {
  const hiddenColumns: string[] = [];
  if (dataObject.schema.find(item => item.ColumnName === 'field_header_properties')) {
    if (dataObject.data.length > 0) {
      let fieldHeaderProperties: string = dataObject.data[0]['field_header_properties'];

      if (fieldHeaderProperties.charAt(-1) === '|') {
        fieldHeaderProperties = fieldHeaderProperties.slice(0, -1);
      }
      const aFieldHeaderProperties: string[] = fieldHeaderProperties.split('|');
      aFieldHeaderProperties.forEach(headerProperty => {
        const aFieldHeader = headerProperty.split(';'); // string format is (column name;column caption;hidden)
        if (aFieldHeader.length === 3) {
          if (aFieldHeader[2] === '1') {
            // third item indicates column is hidden
            hiddenColumns.push(aFieldHeader[0]);
          } else if (aFieldHeader[2] === '0') {
            // if column is not hidden use the caption override
            const idx = dataObject.schema.findIndex(item => item.ColumnName === aFieldHeader[0]);
            if (idx !== -1) {
              dataObject.schema[idx].Caption = aFieldHeader[1];
            }
          }
        }
      });
    }
  }

  return hiddenColumns;
};

function getTableCurrency(dataObject: IDataObject): string {
  let tableCurrency: string = '';
  if (dataObject.data.length > 0) {
    tableCurrency = getTrimmedValue(dataObject.data[0].table_$$$);
  }
  return getTrimmedValue(tableCurrency);
}

function getRowCurrency(dataObject: IDataObject): string {
  let rowCurrency: string = '';
  if (dataObject.data.length > 0) {
    rowCurrency =
      getTrimmedValue(dataObject.data[0].row_$$$) !== '' ? dataObject.data[0].row_$$$ : dataObject.data[0].Row_$$$;
  }
  return getTrimmedValue(rowCurrency);
}

function getColumnWithCurrency(dataObject: IDataObject): string[] {
  const columnWithCurrency: string[] = [];

  dataObject.schema.forEach((column: any) => {
    if (column.ColumnName !== TABLE_CURRENCY && column.ColumnName.endsWith('_$$$')) {
      const parentColumn = column.ColumnName.replace('_$$$', '');
      if (dataObject.schema.find(item => item.ColumnName === parentColumn)) {
        columnWithCurrency.push(column.ColumnName);
      }
    }
  });

  return columnWithCurrency;
}

function getGridStyle(
  showPagination: boolean,
  isExpanded: boolean,
  allowResize: boolean,
  gridHeight: string,
  gridWidth: string,
): any {
  let gridStyle = {
    color: 'white',
    display: 'flex',
    width: gridWidth,
    height: '100%',
  };
  if (showPagination === true) {
    gridStyle = { ...gridStyle, height: gridHeight };
  }
  if (allowResize && !isExpanded) {
    gridStyle = {
      ...gridStyle,
      height: CONTRACT_HEIGHT,
    };
  }
  return gridStyle;
}

function getButtonStyle(buttonColor: any): any {
  return {
    color: buttonColor,
    height: '16px',
    width: '16px',
  };
}

function getHeaderButtonStyle(buttonColor: any): any {
  return {
    color: buttonColor,
    height: '16px',
    width: '16px',
  };
}

function voidFunction() {
  return false;
}

function showLoading(
  displayType: string,
  showPagination: boolean,
  gridHeight: string,
  gridWidth: string,
  vGridWidth: string,
  gridContentHeight: any,
  loadingMessage: string,
  isExpanded: boolean,
  allowResize: boolean,
  theme?: Theme,
): any {
  const showActivityIndicator = () => {
    return (
      <GridLoadingIcon
        showLoadingMessage={loadingMessage ? true : false}
        loadingMessage={loadingMessage}
        theme={theme}
      />
    );
  };
  let divStyle: any = {
    alignItems: 'center',
    backgroundColor: theme === Theme.Dark ? '#242f39' : 'white',
    border: '1px solid',
    borderTop: '4px solid var(--default-blue)',
    display: 'block',
    height: 'auto',
    justifyContent: 'center',
    margin: '0px',
    padding: '20px',
    textAlign: 'center',
    verticalAlign: 'middle',
    width: 'auto',
  };

  if (displayType === VERTICAL) {
    divStyle = { ...divStyle, width: vGridWidth };

    return <div style={divStyle}>{showActivityIndicator()}</div>;
  } else {
    divStyle = { ...divStyle, display: 'flex', height: '100%', width: '100%' };

    if (gridContentHeight !== undefined) {
      divStyle = {
        ...divStyle,
        height: gridContentHeight,
      };
    }

    if (showPagination === true) {
      divStyle = { ...divStyle, height: gridHeight, width: gridWidth };
    }

    if (allowResize && !isExpanded) {
      divStyle = {
        ...divStyle,
        height: CONTRACT_HEIGHT,
      };
    }

    return <div style={divStyle}>{showActivityIndicator()}</div>;
  }
}

function fixHiddenColumns(dataObject: IDataObject, hiddenColumns: string[]) {
  hiddenColumns.forEach(col => {
    const idx = dataObject.schema.findIndex(item => item.ColumnName === col);

    if (idx !== -1) {
      dataObject.schema[idx].Hidden = true;
    }
  });
}

function fixCurrencyColumn(col: any, tableCurrency: any, columnWithCurrency: any, data: any, extraColumnProps: any) {
  let hasCurrencyColumn: boolean = false;
  if (tableCurrency !== '' && col.DataTypeName.toLowerCase() === 'money') {
    hasCurrencyColumn = false;
    columnWithCurrency.forEach((column: string) => {
      if (column.replace('_$$$', '') === col.ColumnName) {
        hasCurrencyColumn = true;
      }
    });
    if (!hasCurrencyColumn && col.ColumnName !== tableCurrency) {
      col.Caption = col.ColumnName + ' (' + tableCurrency + ')';
    }
  } else if (col.ColumnName.endsWith('_$$$') && col.ColumnName !== TABLE_CURRENCY) {
    hasCurrencyColumn = false;
    columnWithCurrency.forEach((column: string) => {
      if (column === col.ColumnName) {
        hasCurrencyColumn = true;
      }
    });
    if (col.ColumnName === ROW_CURRENCY) {
      hasCurrencyColumn = true;
    }
    if (hasCurrencyColumn) {
      const result = data.length > 0 ? (getTrimmedValue(data[0][col.ColumnName]) === '' ? false : true) : true;

      if (result) {
        col.Hidden = false;
        col.Caption = col.ColumnName === ROW_CURRENCY ? 'Currency Code' : col.ColumnName.replace('_$$$', ' Currency');
        col.Width = 120;
      }
    }
  }
  if (hasCurrencyColumn) {
    extraColumnProps[col.ColumnName] = {
      ...extraColumnProps[col.ColumnName],
      allowEditing: false,
    };
  }
}

function fixCurrencyColumnData(data: any, columnWithCurrency: any) {
  for (const column of columnWithCurrency) {
    if (getTrimmedValue(data[column.replace('_$$$', '')]) === '') {
      data[column] = '';
    }
  }
}

function fixFloatColumns(col: any, columnsToChange: IColumnPrecision[], columnPrecision?: IColumnPrecision[]) {
  if (col.DataTypeName === 'float') {
    if (col.Format === undefined) {
      if (columnPrecision) {
        for (const column of columnPrecision) {
          if (column.precision !== 'nf' && column.columnName === col.ColumnName) {
            columnsToChange.push({
              columnName: column.columnName,
              precision: column.precision,
            });
          }
        }
      }
    }
  }
}

function fixFloatColumnData(data: any, columnsToChange: IColumnPrecision[]) {
  columnsToChange.forEach((column: IColumnPrecision) => {
    if (column.precision && column.precision !== 'nf') {
      data[column.columnName] =
        data[column.columnName] !== undefined && data[column.columnName] === null
          ? ConvertDouble(data[column.columnName]).toFixed(column.precision)
          : data[column.columnName];
    }
  });
}

function fixPercentageColumns(dataObject: IDataObject, columnPrecision?: IColumnPrecision[]) {
  dataObject.schema.forEach(col => {
    if (col.ColumnName.endsWith('%')) {
      if (col.Format === undefined) {
        col.Format = 'nf'; // special code for no formatting
        if (columnPrecision) {
          for (const column of columnPrecision) {
            if (column.columnName === col.ColumnName) {
              col.Format = column.precision.toString();
              break;
            }
          }
        }
      }
    }
  });
}

function fixSdColumns(dataObject: IDataObject) {
  const sdColumns: string[] = [];
  dataObject.schema.forEach(col => {
    if (col.Format === 'sd') {
      // col.Format = undefined;
      sdColumns.push(col.ColumnName);
    }
  });
  return sdColumns;
}

function generateButtons(cellData: any, editButtons: any[], isButtonColumnVisible: any): any {
  const headerStyle: any = {
    alignItems: 'center',
    display: 'block',
    height: 'auto',
    justifyContent: 'center',
    textAlign: 'left',
    verticalAlign: 'middle',
    width: 'auto',
  };
  const rowData = { original: cellData.data, row: cellData.data };
  const cellInfo = { row: cellData.data };
  return (
    <span style={headerStyle}>
      {' '}
      {editButtons.map((button: any) => {
        if (button.hidden(cellInfo) === false) {
          isButtonColumnVisible.visible = true;
          switch (button.iconType) {
            case IconEnum.Delete:
              return (
                <a
                  key={'aD' + rowData.original._id_}
                  href="#self"
                  onClick={(e: any) => {
                    e.preventDefault();
                    button.onClick(rowData, e);
                  }}
                  title={button.tooltip}
                >
                  <Delete
                    key={'D' + rowData.original._id_}
                    data-testid={'D' + rowData.original._id_}
                    style={getButtonStyle(button.color)}
                  />
                </a>
              );
            case IconEnum.Add:
              return (
                <a
                  key={'aA' + rowData.original._id_}
                  href="#self"
                  onClick={(e: any) => {
                    e.preventDefault();
                    button.onClick(rowData, e);
                  }}
                  title={button.tooltip}
                >
                  <Add
                    key={'D' + rowData.original._id_}
                    data-testid={'A' + rowData.original._id_}
                    style={getButtonStyle(button.color)}
                  />
                </a>
              );
            case IconEnum.Properties:
              return (
                <a
                  key={'aP' + rowData.original._id_}
                  href="#self"
                  onClick={(e: any) => {
                    e.preventDefault();
                    button.onClick(rowData, e);
                  }}
                  title={button.tooltip}
                >
                  <Properties
                    key={'P' + rowData.original._id_}
                    data-testid={'P' + rowData.original._id_}
                    style={getButtonStyle(button.color)}
                  />
                </a>
              );
            case IconEnum.Transactions:
              return (
                <a
                  key={'aT' + rowData.original._id_}
                  href="#self"
                  onClick={(e: any) => {
                    e.preventDefault();
                    button.onClick(rowData, e);
                  }}
                  title={button.tooltip}
                >
                  <Transactions
                    key={'T' + rowData.original._id_}
                    data-testid={'T' + rowData.original._id_}
                    style={getButtonStyle(button.color)}
                  />
                </a>
              );
            case IconEnum.Folder:
              return (
                <a
                  key={'aF' + rowData.original._id_}
                  href="#self"
                  onClick={(e: any) => {
                    e.preventDefault();
                    button.onClick(rowData, e);
                  }}
                  title={button.tooltip}
                >
                  <FolderOpen
                    key={'T' + rowData.original._id_}
                    data-testid={'F' + rowData.original._id_}
                    style={getButtonStyle(button.color)}
                  />
                </a>
              );
            case IconEnum.Clear:
              return (
                <a
                  key={'aC' + rowData.original._id_}
                  href="#self"
                  onClick={(e: any) => {
                    e.preventDefault();
                    button.onClick(rowData, e);
                  }}
                  title={button.tooltip}
                >
                  <Clear
                    key={'T' + rowData.original._id_}
                    data-testid={'C' + rowData.original._id_}
                    style={getButtonStyle(button.color)}
                  />
                </a>
              );
            case IconEnum.Unlink:
              return (
                <a
                  key={'aU' + rowData.original._id_}
                  href="#self"
                  onClick={(e: any) => {
                    e.preventDefault();
                    button.onClick(rowData, e);
                  }}
                  title={button.tooltip}
                >
                  <LinkOffIcon
                    key={'U' + rowData.original._id_}
                    data-testid={'U' + rowData.original._id_}
                    style={getButtonStyle(button.color)}
                  />
                </a>
              );
            case IconEnum.Pause:
              return (
                <a
                  key={'aP' + rowData.original._id_}
                  href="#self"
                  onClick={(e: any) => {
                    e.preventDefault();
                    button.onClick(rowData, e);
                  }}
                  title={button.tooltip}
                >
                  <PauseLogo
                    key={'P' + rowData.original._id_}
                    data-testid={'P' + rowData.original._id_}
                    style={getButtonStyle(button.color)}
                  />
                </a>
              );
            case IconEnum.Resume:
              return (
                <a
                  key={'aR' + rowData.original._id_}
                  href="#self"
                  onClick={(e: any) => {
                    e.preventDefault();
                    button.onClick(rowData, e);
                  }}
                  title={button.tooltip}
                >
                  <ResumeLogo
                    key={'R' + rowData.original._id_}
                    data-testid={'R' + rowData.original._id_}
                    style={getButtonStyle(button.color)}
                  />
                </a>
              );
            default:
              return (
                <a
                  key={'aE' + rowData.original._id_}
                  href="#self"
                  onClick={(e: any) => {
                    e.preventDefault();
                    button.onClick(rowData, e);
                  }}
                  title={button.tooltip}
                >
                  <Edit
                    key={'E' + rowData.original._id_}
                    data-testid={'E' + rowData.original._id_}
                    style={getButtonStyle(button.color)}
                  />
                </a>
              );
          }
        } else {
          return <></>;
        }
      })}
    </span>
  );
}

function generateHeaderButtons(editButtonsHeader: any, props: IProps): any {
  const headerStyle: any = {
    alignItems: 'center',
    display: 'block',
    height: 'auto',
    justifyContent: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
    width: 'auto',
  };
  return (
    <span style={headerStyle}>
      {' '}
      {editButtonsHeader.length === 0
        ? getTrimmedValue(props.iconColumnHeader)
        : editButtonsHeader.map((button: any) => {
            switch (button.iconType) {
              case IconEnum.Add:
                return (
                  <a
                    key={'aHA0'}
                    href="#self"
                    data-testid={button.tooltip}
                    onClick={(e: any) => {
                      e.preventDefault();
                      button.onClick();
                    }}
                    title={button.tooltip}
                  >
                    <Add key={'hA0'} data-testid={'AddIcon'} style={getHeaderButtonStyle(button.color)} />
                  </a>
                );
              case IconEnum.Delete:
                return (
                  <a
                    key={'aHD0'}
                    href="#self"
                    data-testid={button.tooltip}
                    onClick={(e: any) => {
                      e.preventDefault();
                      button.onClick();
                    }}
                    title={button.tooltip}
                  >
                    <Delete data-testid={'DeleteIcon'} key={'hD0'} style={getHeaderButtonStyle(button.color)} />
                  </a>
                );
              case IconEnum.PasteFromClipboard:
                return (
                  <a
                    key={'aHP0'}
                    data-testid={button.tooltip}
                    href="#self"
                    onClick={(e: any) => {
                      e.preventDefault();
                      button.onClick();
                    }}
                    title={button.tooltip}
                  >
                    <PasteFromClipboard
                      data-testid={'PasteIcon'}
                      key={'hP0'}
                      style={getHeaderButtonStyle(button.color)}
                    />
                  </a>
                );
              case IconEnum.Edit:
                return Edit;
              default:
                return <></>;
            }
          })}
    </span>
  );
}

const getStorageKey = (dataObject: IDataObject, displayType: string, gridTitle?: string) => {
  if (displayType === VERTICAL) {
    return undefined;
  }
  if (dataObject.sqlCommandText) {
    const title = gridTitle ? gridTitle.replace(/[^a-zA-Z0-9]/g, '') : '';
    const execCommandAry = dataObject.sqlCommandText.toUpperCase().split('EXEC ');
    if (execCommandAry.length > 1) {
      const spNameAry = execCommandAry[1].split(' ');
      return spNameAry[0] + '.' + title;
    }
  }
  return undefined;
};

export const generatePageSizeOptions = (pageSizeOptions: any[], dataLength: number) => {
  if (pageSizeOptions[pageSizeOptions.length - 1] !== dataLength) {
    if (dataLength > 100) {
      pageSizeOptions = [10, 20, 25, 50, 100];
      if (dataLength < 200) {
        pageSizeOptions.push(dataLength);
      } else if (pageSizeOptions[pageSizeOptions.length - 1] !== 200) {
        pageSizeOptions.push(200);
      }
    } else if (dataLength < 100 && dataLength >= 50) {
      pageSizeOptions = [10, 20, 25, 50];
      if (dataLength !== 50) {
        pageSizeOptions.push(dataLength);
      }
    } else if (dataLength < 50 && dataLength >= 25) {
      pageSizeOptions = [10, 20, 25];
      if (dataLength !== 25) {
        pageSizeOptions.push(dataLength);
      }
    } else if (dataLength < 25 && dataLength >= 20) {
      pageSizeOptions = [10, 20];
      if (dataLength !== 20) {
        pageSizeOptions.push(dataLength);
      }
    } else if (dataLength < 20 && dataLength >= 10) {
      pageSizeOptions = [10];
      if (dataLength !== 10) {
        pageSizeOptions.push(dataLength);
      }
    } else if (dataLength < 10) {
      pageSizeOptions = [];
      pageSizeOptions.push(dataLength);
    }
  }

  return pageSizeOptions;
};

const setPageSize = (pageSizeOptions: any[], pageSize: number, storageKey?: string, pageSizeDefault?: number) => {
  if (!storageKey) {
    return;
  }
  const localStoreJson = window.localStorage.getItem(storageKey);
  if (!localStoreJson) {
    return;
  }
  const localStoreInfo = JSON.parse(localStoreJson);

  if (pageSizeDefault !== -1 && pageSizeOptions.includes(localStoreInfo.pageSize)) {
    return;
  }
  localStoreInfo.pageSize = pageSize;
  window.localStorage.setItem(storageKey, JSON.stringify(localStoreInfo));
};

const removeSelectedRows = (storageKey?: string) => {
  if (!storageKey) {
    return;
  }
  const localStoreJson = window.localStorage.getItem(storageKey);
  if (!localStoreJson) {
    return;
  }
  const localStoreInfo = JSON.parse(localStoreJson);
  if (typeof localStoreInfo === 'object') {
    let selectedRowExist: boolean = false;
    if (localStoreInfo.hasOwnProperty('selectedRowKeys')) {
      delete localStoreInfo.selectedRowKeys;
      selectedRowExist = true;
    }
    if (localStoreInfo.hasOwnProperty('focusedRowKey')) {
      delete localStoreInfo.focusedRowKey;
      selectedRowExist = true;
    }
    if (selectedRowExist) {
      window.localStorage.setItem(storageKey, JSON.stringify(localStoreInfo));
    }
  }
};

const customSave = (storageKey?: string, state?: any, viewSource?: string) => {
  if (!storageKey || !state) {
    return;
  }
  state = { ...state, viewSource };
  window.localStorage.setItem(storageKey, JSON.stringify(state));
};

const customLoad = (storageKey?: string, viewSource?: string) => {
  if (!storageKey) {
    return;
  }
  const localStoreJson = window.localStorage.getItem(storageKey);
  if (!localStoreJson) {
    return {};
  }
  const localStoreInfo = JSON.parse(localStoreJson);
  if (storageKey === 'RPT_LABELING_METRICS.') {
    delete localStoreInfo.columns;
  }
  if (localStoreInfo.viewSource !== viewSource) {
    delete localStoreInfo.pageSize;
    delete localStoreInfo.pageIndex;
    delete localStoreInfo.filterValue;
  }
  return localStoreInfo;
};

const resetStore = (storageKey?: string) => {
  if (!storageKey) {
    return;
  }
  window.localStorage.removeItem(storageKey);
};

const getGroupPanelCount = (data: any[], extraColumnProps?: any) => {
  const uniqueSet = new Set();
  if (extraColumnProps) {
    const groupColumns = Object.keys(extraColumnProps).filter(
      (key: string) => extraColumnProps[key].groupIndex !== undefined,
    );
    if (groupColumns.length) {
      groupColumns.sort((a: string, b: string) =>
        extraColumnProps[a].groupIndex < extraColumnProps[b].groupIndex ? -1 : 1,
      );
      data.forEach((row: any) => {
        let key = '';
        groupColumns.forEach((column: string) => {
          key += `_${row[column]}`;
          uniqueSet.add(key);
        });
      });
    }
  }
  return uniqueSet.size;
};

const textAreaEditCellComponent = (event: any) => {
  if (event) {
    return (
      <TextArea
        height="110px"
        value={event.data.value}
        onKeyDown={(args: any) => {
          if (args.event.keyCode === 13) {
            args.event.stopPropagation();
          }
        }}
        className="inline-textArea"
        onValueChanged={(e: any) => {
          if (e && e.event && (e.value || e.value === '')) {
            event.data.setValue(e.value);
          }
        }}
      />
    );
  }
};

const calculateCustomSummary = (options: any) => {
  if (options.name === 'custom-count') {
    switch (options.summaryProcess) {
      case 'start':
        options.totalValue = 0;
        break;
      case 'calculate':
        options.totalValue += 1;
        break;
    }
  }
};

const mapStateToProps = (stateFromStore: any) => {
  return {
    allowAdd: stateFromStore.globalRdcr.allowAdd,
    allowDelete: stateFromStore.globalRdcr.allowDelete,
    allowEdit: stateFromStore.globalRdcr.allowEdit,
    heightToRemove: stateFromStore.globalRdcr.heightToRemove,
    userGroups: stateFromStore.globalRdcr.userGroups,
  };
};

export default connect(mapStateToProps)(GridControl);
