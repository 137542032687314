import ActionButton, { ButtonType } from 'components/Buttons/ActionButton';
import _ from 'lodash';
import React, { memo } from 'react';
import { FileType } from 'util/enums/Enum';
import { getTrimmedValue, isStringNullOrWhitespace } from 'util/helpers/helperFunctions';
import CurrencyTextbox from './CurrencyTextbox';
import './TextBox.css';

export const TextBoxStyle = {
  backgroundColor: '#28333c',
  border: 'none',
  color: 'var(--text)',
  fontSize: 'var(--main-font-size)',
  height: '22px',
  minWidth: '120px',
  outline: 'none',
  paddingLeft: 6,
  width: '25vw',
};

export const NumberTextBoxStyle = {
  backgroundColor: '#28333c',
  border: 'none',
  color: 'var(--text)',
  fontSize: 'var(--main-font-size)',
  height: '22px',
  minWidth: '120px',
  outline: 'none',
  paddingLeft: 6,
  paddingRight: 6,
  textAlign: 'right',
  width: '10vw',
};

export interface IProps {
  name: string;
  value: string | number | null;
  dataType?: string;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  onChangeWithValidation?: (event: any) => void;
  isDisabled?: boolean;
  isReadonly: boolean;
  precision?: number;
  appendText?: string;
  appendTextLeft?: string;
  style?: any;
  theme?: string;
  id?: string;
  numberOfDigits?: number;
  fileType?: FileType;
}

const Textbox = (props: IProps) => {
  let input;
  const value = props.value !== null && props.value !== undefined ? props.value : '';
  let textBoxStyle = { ...TextBoxStyle, ...props.style };
  const appendTextStyle: any = {
    paddingLeft: '4px',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
  };
  let appendText = props.appendText;

  const appendTextLeftStyle: any = {
    paddingLeft: '4px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
  };
  let appendTextLeft = props.appendTextLeft;
  let type: any;
  if (props.dataType) {
    type = props.dataType.toUpperCase();
  }

  if (appendText && !isStringNullOrWhitespace(appendText)) {
    textBoxStyle = { ...TextBoxStyle, width: '10vw' };
  }
  if (type === 'PERCENT') {
    textBoxStyle = { ...NumberTextBoxStyle, width: '5vw' };
    type = 'NUMBER';
    appendText = '% ' + (appendText === undefined ? '' : appendText);
  } else if (type === 'CURRENCY' || type === 'NUMBER' || type === 'NUMERIC') {
    textBoxStyle = { ...NumberTextBoxStyle };
  }

  textBoxStyle = { ...textBoxStyle, ...props.style };

  if (props.isDisabled) {
    textBoxStyle = { ...textBoxStyle, opacity: '0.4' };
  }

  let acceptableFile = '*';
  if (type === 'FILE' && props.fileType !== undefined) {
    switch (props.fileType) {
      case FileType.XLS:
        acceptableFile = 'application/vnd.ms-excel';
        break;
      case FileType.CSV:
        acceptableFile = '.csv';
        break;
      case FileType.XLSX:
        acceptableFile = '.xlsx';
        break;
      default:
        acceptableFile = '*';
        break;
    }
  }
  if (type === 'CURRENCY') {
    input = (
      <CurrencyTextbox
        onChange={props.onChange}
        onChangeWithValidation={props.onChangeWithValidation}
        precision={props.precision}
        style={textBoxStyle}
        isDisabled={props.isDisabled}
        isReadonly={props.isReadonly}
        name={props.name}
        id={props.id}
        value={value.toString()}
      />
    );
  } else {
    if (props.isReadonly) {
      input = (
        <span data-testid={props.name} style={{ paddingLeft: 6 }}>
          {value}
        </span>
      );
    } else {
      if (type === 'NUMBER' || type === 'NUMERIC') {
        input = (
          <input
            className={props.theme === 'light' ? 'textbox light' : 'textbox'}
            onChange={(event: any) => percentFormat(event, props)}
            data-testid={props.name}
            style={textBoxStyle}
            readOnly={props.isReadonly}
            type="Search"
            name={props.name}
            id={props.id}
            value={value.toString()}
            onBlur={props.onBlur}
            disabled={props.isDisabled === true ? true : false}
          />
        );
      } else if (type === 'FILE') {
        const fileTypeId = `file-${props.name}`;
        input = (
          <React.Fragment>
            <input
              className={props.theme === 'light' ? 'textbox light' : 'textbox'}
              style={{ ...textBoxStyle }}
              data-testid={props.name}
              readOnly={true}
              type="Search"
              id={props.id}
              value={getTrimmedValue(value)}
              onBlur={props.onBlur}
            />
            <label style={{ cursor: 'pointer', paddingLeft: '10px' }}>
              <ActionButton
                buttonText={'Browse...'}
                buttonType={ButtonType.Default}
                className="button-file-type"
                onClick={() => document.getElementById(fileTypeId)?.click()}
              />
              <input
                id={fileTypeId}
                type="file"
                onChange={props.onChange}
                style={{ display: 'none' }}
                name={props.name}
                accept={acceptableFile}
              />
            </label>
          </React.Fragment>
        );
      } else {
        input = (
          <input
            className={props.theme === 'light' ? 'textbox light' : 'textbox'}
            onChange={props.onChange}
            style={textBoxStyle}
            data-testid={props.name}
            readOnly={props.isReadonly}
            type="Search"
            name={props.name}
            id={props.id}
            value={value.toString()}
            onBlur={props.onBlur}
            disabled={props.isDisabled === true ? true : false}
          />
        );
      }
    }
  }

  return (
    <div style={{ flexWrap: 'nowrap', display: 'inline-flex' }}>
      {appendTextLeft && (
        <span data-testid={props.name} style={appendTextLeftStyle}>
          {appendTextLeft}&nbsp;
        </span>
      )}
      <div className={'virtus-textbox'}>{input}</div>
      {appendText && (
        <span data-testid={props.name} style={appendTextStyle}>
          &nbsp;{appendText}
        </span>
      )}
    </div>
  );
};

const percentFormat = (event: any, props: IProps) => {
  const value: any = event.target.value !== null && event.target.value !== undefined ? event.target.value : '';
  if (isNaN(value) && !(value.length === 1 && (value[0] === '.' || value[0] === '-' || value[0] === '+'))) {
    return;
  }

  if (value.length <= (props.numberOfDigits === undefined ? 12 : props.numberOfDigits) && props.onChange) {
    props.onChange(event);
  }
};

function arePropsEqual(prevProps: IProps, nextProps: IProps) {
  return _.isEqual(_.omit(prevProps, _.functions(prevProps)), _.omit(nextProps, _.functions(nextProps)));
}

export default memo(Textbox, arePropsEqual);
