import { ArrowForward, Settings } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowDown from '@mui/icons-material/ArrowDownward';
import Move from '@mui/icons-material/Description';
import Edit from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import Magnify from '@mui/icons-material/Search';
import RefineSearch from '@mui/icons-material/YoutubeSearchedFor';
import 'components/Buttons/ActionButton.css';
import BtnRunG from 'images/btn_RunG.gif';
import Copy from 'images/create-copy.svg';
import Delete from 'images/delete-white.svg';
import React, { useRef } from 'react';
import { StyledTooltip } from 'util/helpers/CommonComponents';
import { getTrimmedValue } from 'util/helpers/helperFunctions';

export enum ButtonType {
  Cancel = 'Cancel',
  Calculate = 'Calculate',
  Copy = 'Copy',
  Confirm = 'Confirm',
  Delete = 'Delete',
  Default = 'Default',
  Back = 'Back',
  Page = 'Page',
  FormButton = 'FormButton',
  QCValidation = 'QCValidation',
  Submit = 'Submit',
}

export enum IconType {
  Setting,
  Copy,
  Add,
  Edit,
  RefineSearch,
  Magnify,
  QCValidation,
  ArrowBack,
  ArrowDown,
  Move,
  Print,
  Delete,
  ArrowForward,
}

const useFocus = () => {
  const htmlElRef: any = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };
  return [htmlElRef, setFocus];
};

export interface IProps {
  buttonText?: string;
  children?: any;
  className?: any;
  onClick?: any;
  disabled?: boolean;
  buttonType: ButtonType;
  IconType?: IconType;
  display?: boolean;
  title?: string;
  style?: any;
}

const GetButtonTextWithAccessKey: React.SFC<any> = buttonType => {
  if (getTrimmedValue(buttonType) !== '') {
    return (
      <span>
        <span style={{ textDecoration: 'underline' }}>{buttonType.substring(0, 1)}</span>
        {buttonType.substring(1)}
      </span>
    );
  }
  return <></>;
};

function getButtonText(props: IProps, children: any) {
  let btnText = children;
  if (props.buttonType === undefined && getTrimmedValue(props.buttonText) !== '') {
    btnText = props.buttonText;
  } else if (props.buttonType !== undefined && getTrimmedValue(props.buttonText) !== '') {
    btnText = props.buttonText;
  } else if (
    props.buttonType !== undefined &&
    getTrimmedValue(props.buttonText) === '' &&
    getTrimmedValue(btnText) === ''
  ) {
    btnText = props.buttonType;
  }
  return getTrimmedValue(btnText);
}

function getButtonIcon(iconType?: IconType) {
  switch (iconType) {
    case IconType.Setting:
      return <Settings />;
    case IconType.Add:
      return <Add />;
    case IconType.Edit:
      return <Edit style={{ width: '16px', height: '16px' }} />;
    case IconType.Copy:
      return <img key={0} src={Copy} alt="Copy" />;
    case IconType.RefineSearch:
      return <RefineSearch />;
    case IconType.Magnify:
      return <Magnify />;
    case IconType.QCValidation:
      return <img src={BtnRunG} alt="BtnRunG" />;
    case IconType.ArrowBack:
      return <ArrowBack />;
    case IconType.ArrowDown:
      return <ArrowDown />;
    case IconType.Move:
      return <Move style={{ color: 'var(--white)' }} />;
    case IconType.Print:
      return <PrintIcon />;
    case IconType.Delete:
      return <img key={0} src={Delete} alt="Delete" />;
    case IconType.ArrowForward:
      return <ArrowForward style={{ color: 'var(--fis-green)' }} />;
  }
  return <></>;
}

const ActionButton: React.SFC<IProps> = (props: IProps) => {
  const [inputRef, setInputFocus] = useFocus();
  setInputFocus();
  let ref;
  const buttonText = getButtonText(props, props.children);
  let accessKey = buttonText === '' ? undefined : buttonText.substring(0, 1);
  let className = 'button-default';
  let buttonChildComponent = GetButtonTextWithAccessKey(buttonText);
  const display = getTrimmedValue(props.display) === '' || props.display ? '' : 'none';
  switch (props.buttonType) {
    case ButtonType.Cancel:
      className = 'button-cancel';
      break;
    case ButtonType.Confirm:
      accessKey = 'o';
      buttonChildComponent = (
        <span>
          C<span style={{ textDecoration: 'underline' }}>o</span>nfirm
        </span>
      );
      break;
    case ButtonType.Copy:
      accessKey = 'p';
      buttonChildComponent = (
        <span>
          Co<span style={{ textDecoration: 'underline' }}>p</span>y
        </span>
      );
      break;
    case ButtonType.Calculate:
      accessKey = 't';
      buttonChildComponent = (
        <span>
          Calcula<span style={{ textDecoration: 'underline' }}>t</span>e
        </span>
      );
      break;
    case ButtonType.Delete:
      className = 'button-delete';
      ref = inputRef;
      break;
    case ButtonType.Back:
      className = 'button-back';
      buttonChildComponent = (
        <span>
          {'< '}
          {GetButtonTextWithAccessKey('Back')}
        </span>
      );
      break;
    case ButtonType.Default:
      className = props.className !== undefined ? props.className : 'button-default';
      break;
    case ButtonType.Page:
      className = 'page-button';
      buttonChildComponent = (
        <span style={{ height: '16px', width: '16px' }}>
          {getButtonIcon(props.IconType)} {GetButtonTextWithAccessKey(buttonText)}
        </span>
      );
      break;
    case ButtonType.FormButton:
      className = props.className !== undefined ? props.className : 'button-default';
      accessKey = undefined;
      buttonChildComponent = (
        <span style={{ height: '16px', width: '16px' }}>
          {getButtonIcon(props.IconType)} {buttonText}
        </span>
      );
      break;
    case ButtonType.QCValidation:
      className = 'button-qcValidation';
      buttonChildComponent = (
        <span style={{ height: '16px', width: '16px' }}>
          {getButtonIcon(props.IconType)} {GetButtonTextWithAccessKey(buttonText)}
        </span>
      );
      break;
    case ButtonType.Submit:
      className = props.className !== undefined ? props.className : 'button-default';
      accessKey = undefined;
      break;
  }
  return (
    <StyledTooltip title={getTrimmedValue(props.title)}>
      <React.Fragment>
        <button
          data-testid={buttonText}
          className={'button ' + className}
          ref={ref}
          accessKey={accessKey}
          onClick={props.buttonType === ButtonType.Submit ? undefined : props.onClick}
          style={props.style ? { ...props.style, display } : { display }}
          type={props.buttonType === ButtonType.Submit ? 'submit' : undefined}
          disabled={props.disabled}
        >
          {buttonChildComponent}
        </button>
      </React.Fragment>
    </StyledTooltip>
  );
};

export default ActionButton;
