import DropdownControlSqlRow from 'components/DropDown/DropDownControlSql/DropDownControlSqlRow';
import { MethodType } from 'components/DropDown/MethodType';
import { EditModal } from 'components/Modals/EditModal/EditModal';
import TextboxControlRow from 'components/TextboxControl/TextboxControlRow';
import { saveData } from 'data/DataConnector';
import React from 'react';
import { ControllerNameEnum } from 'util/enums/Enum';
import { getTrimmedValue, getUsername } from 'util/helpers/helperFunctions';
import { EditBaseClass } from 'views/EditBaseClass';

interface IProps {
  params: any;
  onClose: () => void;
  onSave: () => void;
}

interface IState {
  agentName: string;
  dealId: string;
  dealName: string;
  isLoading: boolean;
}

// todo: verify the dropdown sp
class SelectDealVDA extends EditBaseClass<IProps, IState> {
  private username: string;
  constructor(props: IProps) {
    super(props);
    this.username = getUsername();
    this.state = {
      agentName: '',
      dealId: getTrimmedValue(this.props.params.entityId),
      dealName: getTrimmedValue(this.props.params.dealName),
      isLoading: true,
    };
  }

  public componentDidMount() {
    this.initialize();
  }

  public render() {
    return (
      <EditModal
        isLoading={this.state.isLoading}
        modalHeader={'Select Virtus Data Analyst'}
        isModalOpen={true}
        onCloseModal={this.props.onClose}
        onSave={this.onSave}
      >
        <table>
          <tbody>
            <TextboxControlRow
              name="dealId"
              label="Deal ID"
              isRequired={false}
              value={this.state.dealId}
              isReadonly={true}
              onChange={this.onChangeHandler}
            />
            <TextboxControlRow
              name="dealName"
              label="Deal Name"
              isRequired={false}
              value={this.state.dealName}
              isReadonly={true}
              onChange={this.onChangeHandler}
            />
            <DropdownControlSqlRow
              displayField="agent_name"
              valueField="agent_name"
              name="agentName"
              methodRoute="CommonFunctions/CdosaUserList?recordsetNumber=1"
              methodType={MethodType.Get}
              label="Data Analyst"
              value={this.state.agentName}
              onChange={this.onChangeHandler}
              getDefaultValue={this.getDefaultAgentName}
            />
          </tbody>
        </table>
      </EditModal>
    );
  }

  private getDefaultAgentName = (value: any) => this.setState({ agentName: value });

  private initialize() {
    this.setState({ isLoading: false });
  }

  private onSave = async () => {
    const params = {
      agentName: this.state.agentName,
      agentType: 'VDA',
      entityId: this.state.dealId,
      userId: this.username,
    };
    const response = await saveData(
      ControllerNameEnum.LiabilitiesCapitalStructure,
      'VrtsDealDirectoryAgentPut',
      params,
    );
    if (!response.hasError) {
      this.props.onSave();
    }
  };
}

export default SelectDealVDA;
