import { DialogContent, DialogContentText } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import DxPopupModal from 'components/hoc/PopupModal/DxPopupModal';
import ModalContent from 'components/hoc/PopupModal/ModalContent';
import { ComponentType } from 'components/hoc/PopupModal/ModalEnum';
import ModalFooter from 'components/hoc/PopupModal/ModalFooter';
import ModalHeader from 'components/hoc/PopupModal/ModalHeader';
import 'components/Modals/AlertModal/AlertModal.css';
import 'components/PageMessageControl/PageMessageControl.css';
import * as ColorPalette from 'components/Themes/theme';
import { ScrollView } from 'devextreme-react';
import React from 'react';
import { PageMessageIconType } from 'util/enums/PageMessageIconTypeEnum';

export interface IProps {
  isModalOpen: boolean;
  alertMessage: string;
  onCloseModal: any;
  iconType?: PageMessageIconType;
  headerText?: string;
  allowCopy?: boolean;
}

const alertModalStyle = {
  background: ColorPalette.colors.bgDark,
  color: ColorPalette.colors.text,
  paddingBottom: '38px',
  width: '100%',
};

export const AlertModal: React.SFC<IProps> = (props: IProps) => {
  let icon;
  switch (props.iconType) {
    case PageMessageIconType.Information:
      icon = <InfoIcon style={{ width: '40px', height: '40px' }} />;
      break;
    default:
      icon = <WarningIcon style={{ width: '40px', height: '40px' }} />;
      break;
  }
  return (
    <DxPopupModal
      isModalOpen={props.isModalOpen}
      modalHeader={
        <ModalHeader
          isComponentLoading={false}
          componentHeader={props.headerText === undefined ? 'Alert' : props.headerText}
          onCloseClicked={props.onCloseModal}
          componentType={ComponentType.Alert}
        />
      }
    >
      <div className="confirmation-modal" style={alertModalStyle}>
        <ScrollView
          scrollByContent={true}
          bounceEnabled={false}
          direction={'both'}
          showScrollbar={'always'}
          scrollByThumb={true}
        >
          <DialogContent style={{ padding: '0px' }}>
            <DialogContentText
              component={'div'}
              style={{
                color: 'white',
                fontSize: '14px',
                fontWeight: 'normal',
                wordBreak: 'break-word',
              }}
            >
              <ModalContent isLoading={false}>
                <table>
                  <tbody>
                    <tr>
                      <td>{icon}</td>
                      <td>
                        <div style={{ paddingLeft: '20px' }}>
                          <span
                            style={{ whiteSpace: 'pre-wrap' }}
                            dangerouslySetInnerHTML={{
                              __html: props.alertMessage.toString(),
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ModalContent>
            </DialogContentText>
          </DialogContent>
        </ScrollView>
      </div>
      <ModalFooter
        isComponentLoading={false}
        onCancelClicked={props.onCloseModal}
        onOperatioConfirmed={props.onCloseModal}
        componentType={ComponentType.Alert}
        onCopyClicked={
          props.allowCopy
            ? () => {
                onCopyClicked(props.alertMessage);
                props.onCloseModal();
              }
            : undefined
        }
      />
    </DxPopupModal>
  );
};

const onCopyClicked = (text: string) => {
  navigator.clipboard.writeText(text);
};
