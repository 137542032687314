import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { globalHistory } from '@reach/router';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from 'config';
import ErrorBoundary from 'ErrorBoundary';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'stores/configureStore';
import App from './app/App';
import './index.css';
import * as serviceWorker from './serviceWorker';

let reactPlugin = new ReactPlugin();
let appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: config.appInsightsKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: globalHistory },
    },
  },
});
appInsights.loadAppInsights();

const renderApp = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

const rootDOMElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootDOMElement!);
root.render(renderApp());

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
