import ModalContent from 'components/hoc/PopupModal/ModalContent';
import { ComponentType } from 'components/hoc/PopupModal/ModalEnum';
import ModalFooter from 'components/hoc/PopupModal/ModalFooter';
import ModalHeader from 'components/hoc/PopupModal/ModalHeader';
import 'components/Modals/EditModal/EditModal.css';
import * as ColorPalette from 'components/Themes/theme';
import { ScrollView } from 'devextreme-react';
import Popup from 'devextreme-react/popup';
import React, { useState } from 'react';

export interface IProps {
  disableDeleteButton?: boolean;
  showDeleteButton?: boolean;
  isModalOpen: boolean;
  isLoading: boolean;
  modalHeader: string;
  onSave: any;
  saveButtonText?: string;
  disableSaveButton?: boolean;
  onDeleteClicked?: any;
  onPasteClicked?: any;
  onCopyClicked?: any;
  onCloseModal?: any;
  footerText?: string;
  showSaveBtn?: boolean;
  operationCancelledBtnText?: string;
  style?: any;
  customHeaderButtons?: any[];
  copyButtonText?: string;
  pasteButtonText?: string;
  initialized?: boolean;
  extraProps?: any;
  reference?: any;
  contentStyle?: any;
  children?: any;
}

const editModalStyle = {
  background: ColorPalette.colors.bgDark,
  color: ColorPalette.colors.text,
  fontSize: '14px',
  paddingBottom: '38px',
  width: '100%',
};

export const EditModal: React.FunctionComponent<IProps> = (props: IProps) => {
  const extraProps: any = { ...props.extraProps };
  const [hideCancel, sethideCancel] = useState(false);
  if (props.initialized === false) {
    extraProps.position = {
      at: 'center',
      my: 'center',
      of: window,
    };
  }
  if (props.isLoading) {
    extraProps.minHeight = 'auto';
  }

  const onSavedClicked = () => {
    sethideCancel(true);
    props.onSave();
  };

  const editStyle = { ...editModalStyle, ...props.style };
  return (
    <Popup
      ref={props.reference}
      onContentReady={(e: any) => {
        e.component.content().parentElement.classList.add('DxPopup');
      }}
      animation={{
        hide: {
          duration: 0,
          from: { position: { my: 'center', at: 'center', of: window } },
          to: { position: { my: 'center', at: 'center', of: window } },
          type: 'slide',
        },
        show: {
          duration: 0,
          from: { position: { my: 'center', at: 'center', of: window } },
          to: { position: { my: 'center', at: 'center', of: window } },
          type: 'slide',
        },
      }}
      id={'pop-modal'}
      visible={props.isModalOpen}
      resizeEnabled={true}
      defaultPosition={{
        at: 'center',
        my: 'center',
        of: window,
      }}
      defaultHeight={'auto'}
      minHeight={'170px'}
      defaultWidth={'auto'}
      minWidth={'200px'}
      shadingColor={'rgba(0,0,0,0.5)'}
      {...extraProps}
      titleComponent={() => (
        <ModalHeader
          isComponentLoading={props.isLoading}
          componentHeader={props.isLoading ? '' : props.modalHeader}
          onPasteClicked={props.onPasteClicked}
          onCopyClicked={props.onCopyClicked}
          onCloseClicked={props.onCloseModal}
          onPrintClicked={props.onCloseModal}
          copyButtonText={props.copyButtonText}
          pasteButtonText={props.pasteButtonText}
          componentType={ComponentType.Edit}
          customHeaderButtons={props.customHeaderButtons}
          hideCancel={props.isLoading && hideCancel}
        />
      )}
    >
      <div className="edit-modal" style={editStyle}>
        <ScrollView
          scrollByContent={true}
          bounceEnabled={false}
          direction={'both'}
          showScrollbar={'onHover'}
          scrollByThumb={true}
        >
          <div className="edit-modal-content" style={{ ...props.contentStyle }}>
            <ModalContent isLoading={props.isLoading}>{props.children}</ModalContent>
          </div>
        </ScrollView>
      </div>
      <div>
        <ModalFooter
          showDeleteButton={props.showDeleteButton}
          onDeleteClicked={props.onDeleteClicked}
          btnText={props.saveButtonText}
          isComponentLoading={props.isLoading}
          onCancelClicked={props.onCloseModal}
          disableDeleteButton={props.disableDeleteButton}
          onOperatioConfirmed={onSavedClicked}
          componentType={ComponentType.Edit}
          operationCancelledBtnText={props.operationCancelledBtnText}
          showOperationConfirmedBtn={props.showSaveBtn}
          footerText={props.footerText}
          disableSaveButton={props.disableSaveButton}
          hideCancel={props.isLoading && hideCancel}
        />
      </div>
    </Popup>
  );
};
