import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ComplianceIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H16V16H0z" />
      <path
        fill="#FFF"
        d="M13.444 1.6h-3.25C9.866.672 9.01 0 8 0S6.133.672 5.807 1.6H2.556C1.7 1.6 1 2.32 1 3.2v11.2c0 .88.7 1.6 1.556 1.6h10.888C14.3 16 15 15.28 15 14.4V3.2c0-.88-.7-1.6-1.556-1.6zM8 1.74c.47 0 .854.39.854.869 0 .478-.384.87-.854.87s-.854-.392-.854-.87c0-.479.384-.87.854-.87zM6.463 12.87L3.39 9.62l1.084-1.145 1.99 2.096 5.062-5.354 1.084 1.154-6.147 6.499z"
      />
    </g>
  </SvgIcon>
);
