import ModalContent from 'components/hoc/PopupModal/ModalContent';
import { ComponentType } from 'components/hoc/PopupModal/ModalEnum';
import ModalFooter from 'components/hoc/PopupModal/ModalFooter';
import React from 'react';
import './InspectorSearch.css';

export interface IProps {
  hideSearchButton?: boolean;
  filterHeader: string;
  onSearchFilterClose: any;
  onSearchClicked: any;
  showSearchFilter: boolean;
  isFormDisabled?: boolean;
  searchBtnText?: string;
  children: React.ReactNode;
}

export class InspectorSearch extends React.Component<IProps> {
  public render() {
    return (
      <div className={'inspector-search'} data-testid="div1-inspector-search">
        <div>
          <ModalContent isLoading={false}>
            <div>
              {this.props.isFormDisabled ? (
                <React.Fragment>
                  {this.props.children}
                  <input
                    data-testid="submit-input"
                    type="submit"
                    style={{ display: 'none' }}
                    onSubmit={this.handleSubmit}
                    value="Submit"
                  />
                </React.Fragment>
              ) : (
                <form onSubmit={this.handleSubmit} data-testid="form-submit">
                  {this.props.children}
                  <input
                    data-testid="submit-input"
                    type="submit"
                    style={{ display: 'none' }}
                    onSubmit={this.handleSubmit}
                    value="Submit"
                  />
                </form>
              )}
            </div>
          </ModalContent>
        </div>
        <div>
          <ModalFooter
            hideSearchButton={this.props.hideSearchButton}
            componentType={ComponentType.Search}
            isComponentLoading={false}
            onCancelClicked={this.props.onSearchFilterClose}
            onOperatioConfirmed={this.props.onSearchClicked}
            btnText={this.props.searchBtnText}
          />
        </div>
      </div>
    );
  }

  private handleSubmit = (event: any) => {
    event.preventDefault();
    if (this.props.onSearchClicked) {
      this.props.onSearchClicked();
    }
  };
}
