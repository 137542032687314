import AddControlRow from 'components/AddControlRow';
import TextBoxControlObject from 'components/TextboxControl/TextboxControlObject';
import _ from 'lodash';
import React, { memo } from 'react';
import { FileType } from 'util/enums/Enum';

interface IProps {
  name: string;
  label: string;
  value: string | number | null;
  dataType?: string;
  isRequired: boolean;
  isReadonly: boolean;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  isValid?: boolean;
  precision?: number;
  onChangeWithValidation?: (event: any) => void;
  validationMessage?: string;
  appendText?: string;
  appendTextLeft?: string;
  style?: any;
  isDisabled?: boolean;
  numberOfDigits?: number;
  fileType?: FileType;
}

function TextboxControlRow(props: IProps) {
  return (
    <AddControlRow
      validationColor={props.isValid === false ? 'red' : 'white'}
      component={
        <TextBoxControlObject
          {...props}
          validationMessage={
            props.validationMessage ? props.validationMessage : 'Value for "' + props.label + '" is required'
          }
        />
      }
      description={props.label}
      isReadOnly={props.isReadonly}
      isRequired={props.isRequired}
    />
  );
}

function arePropsEqual(prevProps: IProps, nextProps: IProps) {
  return _.isEqual(_.omit(prevProps, _.functions(prevProps)), _.omit(nextProps, _.functions(nextProps)));
}

export default memo(TextboxControlRow, arePropsEqual);
// export default TextboxControlRow;
