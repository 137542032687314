import {
  CLEAR_SEARCH_CRITERIA,
  IS_BACK_CLICKED,
  SET_SEARCH_CRITERIA,
} from 'actions/SearchCriteria/searchCriteriaActionTypes';

export enum SearchTabEnum {
  None,
  GlobalInfo_Facility,
  GlobalInfo_Issues,
  GlobalInfo_IssuersBorrowers,
  GlobalInfo_BanksCounterparties,
}

interface IState {
  searchCriteriaDict: any;
  isBackClicked: boolean;
}

/*
interface ISearchCriteria {
  searchCriteria: any;
  searchCriteriaStateObj: any;
  searchTab: string;
}
*/

const defaultState: IState = {
  isBackClicked: false,
  searchCriteriaDict: {},
};

export const searchCriteaRdcr = (state = defaultState, action: any) => {
  switch (action.type) {
    case SET_SEARCH_CRITERIA:
      return {
        ...state,
        searchCriteriaDict: {
          ...state.searchCriteriaDict,
          [action.data.searchTab]: {
            searchCriteria: action.data.searchCriteria,
            searchCriteriaStateObj: action.data.searchCriteriaStateObj,
          },
        },
      };
    case CLEAR_SEARCH_CRITERIA:
      delete state.searchCriteriaDict[action.data.searchTab];
      return state;
    case IS_BACK_CLICKED:
      return {
        ...state,
        isBackClicked: action.data,
      };

    default:
      return state;
  }
};
