import AddControlRow from 'components/AddControlRow';
import {
  DropDownControlCodeTableObject,
  IProps,
} from 'components/DropDown/DropDownControlCodeTable/DropDownControlCodeTableObject';
import React, { memo } from 'react';
import { shallowCompare } from 'util/helpers/helperFunctions';

interface IProp extends IProps {
  label: string;
}

function DropDownControlCodeTableRow(props: IProp) {
  const component = (
    <DropDownControlCodeTableObject
      {...props}
      validationMessage={
        props.validationMessage ? props.validationMessage : 'Value for "' + props.label + '" is required'
      }
    />
  );
  return (
    <AddControlRow
      validationColor={props.isValid === false ? 'red' : 'white'}
      component={component}
      description={props.label}
      isReadOnly={props.isReadonly ? props.isReadonly : false}
      isRequired={props.isRequired ? props.isRequired : false}
    />
  );
}

function arePropsEqual(prevProps: IProps, nextProps: IProps) {
  const oldProps = {
    ...prevProps,
  };
  const newProps = {
    ...nextProps,
  };
  if ('style' in oldProps) {
    delete oldProps.style;
  }
  if ('style' in newProps) {
    delete newProps.style;
  }

  if ('getDefaultValue' in oldProps) {
    delete oldProps.getDefaultValue;
  }
  if ('getDefaultValue' in newProps) {
    delete newProps.getDefaultValue;
  }

  if ('onChange' in oldProps) {
    delete oldProps.onChange;
  }
  if ('onChange' in newProps) {
    delete newProps.onChange;
  }
  return shallowCompare(oldProps, newProps);
}

export default memo(DropDownControlCodeTableRow, arePropsEqual);
