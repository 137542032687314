import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const HomeIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H16V16H0z" />
      <path fill="#FFF" d="M10 10L6 10 6 14 3 14 3 8 0 8 8 1 16 8 13 8 13 14 10 14z" />
    </g>
  </SvgIcon>
);
